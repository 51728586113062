import AnotherModal from '~/components/modals/AnotherModal.vue';
import SubscriptionModal from '~/components/modals/personal/SubscriptionModal/SubscriptionModal.vue';
import GiftCertModal from '~/components/modals/personal/GiftCertModal/GiftCertModal.vue';
import GiftCertPurchased from '~/components/modals/personal/GiftCertPurchasedModal/GiftCertPurchased.vue';
import UpdatePaymentInfoModal from '~/components/modals/personal/UpdatePaymentInfoModal/UpdatePaymentInfoModal.vue';
import EmailVerification from '~/components/modals/personal/EmailVerification/EmailVerification.vue';
import PhoneVerification from '~/components/modals/personal/PhoneVerification/PhoneVerification.vue';
import AddContact from '~/components/modals/personal/AddContact/AddContact.vue';
import CreateGroupChat from '~/components/modals/personal/CreateGroupChat/CreateGroupChat.vue';
import ExposureSideBar from '~/components/side-bar/personal/exposure-sidebar/ExposureSideBar.vue';
import UploadFile from '~/components/modals/personal/UploadFileModal/UploadFile.vue';
import RequestNewCompany from '~/components/modals/personal/RequestNewCompany.vue';
import RequestDeletionGuide from '~/components/modals/personal/RequestDeletionGuide.vue';
import UpdateTagModal from '~/components/modals/personal/UpdateTagModal/UpdateTagModal.vue';
import BlockContactModal from '~/components/modals/personal/BlockContactModal/BlockContactModal.vue';
import RemoveContactModal from '~/components/modals/personal/RemoveContactModal/RemoveContactModal.vue';
import IncomingCallModal from '~/components/modals/personal/IncomingCallModal/IncomingCallModal.vue';
import OutgoingCallModal from '~/components/modals/personal/OutgoingCallModal/OutgoingCallModal.vue';
import CallConnectedModal from '~/components/modals/personal/CallConnectedModal/CallConnectedModal.vue';

//dev
import SelectDevBranch from '~/components/modals/dev/SelectDevBranch.vue';

//DialogName can be  personal:upsell | personal:email_scan_disconnect
type DialogName =
    | 'personal:subscription-modal'
    | 'personal:create-group-chat-modal'
    | 'personal:giftcert-modal'
    | 'personal:giftcertpurchased-modal'
    | 'personal:update-payment-info-modal'
    | 'personal:email-verification-modal'
    | 'personal:phone-verification-modal'
    | 'personal:add-contact-modal'
    | 'personal:exposure-sidebar'
    | 'personal:upload-file-modal'
    | 'personal:request-new-company-modal'
    | 'personal:deletion-guide-modal'
    | 'personal:update-tag-modal'
    | 'personal:block-contact-modal'
    | 'personal:remove-contact-modal'
    | 'personal:incoming-call-modal'
    | 'personal:outgoing-call-modal'
    | 'personal:outgoing-call-connected-modal'
    | 'dev:select-dev-branch';

export function useDynamicDialog(name: DialogName, contact?: any) {
    const nuxtApp = useNuxtApp();

    switch (name) {
        case 'personal:deletion-guide-modal':
            return nuxtApp.$dialog.open(RequestDeletionGuide, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });

        case 'dev:select-dev-branch':
            return nuxtApp.$dialog.open(SelectDevBranch, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-[#09090b80]',
                    },
                },
            });

        case 'personal:exposure-sidebar':
            return nuxtApp.$dialog.open(ExposureSideBar, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-[#09090b80]',
                    },
                },
            });

        case 'personal:subscription-modal':
            return nuxtApp.$dialog.open(SubscriptionModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:giftcert-modal':
            return nuxtApp.$dialog.open(GiftCertModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:giftcertpurchased-modal':
            return nuxtApp.$dialog.open(GiftCertPurchased, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:update-payment-info-modal':
            return nuxtApp.$dialog.open(UpdatePaymentInfoModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:email-verification-modal':
            return nuxtApp.$dialog.open(EmailVerification, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:phone-verification-modal':
            return nuxtApp.$dialog.open(PhoneVerification, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:add-contact-modal':
            return nuxtApp.$dialog.open(AddContact, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:create-group-chat-modal':
            return nuxtApp.$dialog.open(CreateGroupChat, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:upload-file-modal':
            return nuxtApp.$dialog.open(UploadFile, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:update-tag-modal':
            return nuxtApp.$dialog.open(UpdateTagModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });

        case 'personal:request-new-company-modal':
            return nuxtApp.$dialog.open(RequestNewCompany, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:block-contact-modal':
            return nuxtApp.$dialog.open(BlockContactModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:remove-contact-modal':
            return nuxtApp.$dialog.open(RemoveContactModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:incoming-call-modal':
            return nuxtApp.$dialog.open(IncomingCallModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:outgoing-call-modal':
            return nuxtApp.$dialog.open(OutgoingCallModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });
        case 'personal:outgoing-call-connected-modal':
            return nuxtApp.$dialog.open(CallConnectedModal, {
                props: {
                    modal: true,
                    closable: false,
                    dismissableMask: true,
                    pt: {
                        mask: 'bg-slate-600',
                    },
                },
            });

        default:
            throw new Error(`Unknown dialog name: ${name}`);
    }
}
