<template>
    <div class="header-div">
        <div class="icon-div">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="380"
                height="380"
                viewBox="0 0 480 480"
                fill="none"
                class="pattern"
                opacity="0.4"
            >
                <defs>
                    <clipPath id="clip-circle">
                        <rect x="190" y="190" width="240" height="240" />
                    </clipPath>
                </defs>
                <g clip-path="url(#clip-circle)">
                    <circle cx="240" cy="240" r="47.5" stroke="#E4E4E7" />
                    <circle cx="240" cy="240" r="79.5" stroke="#E4E4E7" />
                    <circle cx="240" cy="240" r="111.5" stroke="#E4E4E7" />
                    <circle cx="240" cy="240" r="143.5" stroke="#E4E4E7" />
                </g>
            </svg>

            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M21.6489 19.875C20.2211 17.4066 18.0208 15.6366 15.4529 14.7975C16.7231 14.0414 17.7099 12.8892 18.2619 11.5179C18.8139 10.1467 18.9004 8.63213 18.5083 7.2069C18.1162 5.78167 17.2671 4.52456 16.0914 3.62862C14.9156 2.73268 13.4783 2.24745 12.0001 2.24745C10.5219 2.24745 9.08463 2.73268 7.90891 3.62862C6.73318 4.52456 5.88406 5.78167 5.49195 7.2069C5.09984 8.63213 5.18641 10.1467 5.73837 11.5179C6.29033 12.8892 7.27716 14.0414 8.54732 14.7975C5.97951 15.6356 3.77919 17.4056 2.35138 19.875C2.29902 19.9604 2.26429 20.0554 2.24924 20.1544C2.23419 20.2534 2.23912 20.3544 2.26375 20.4515C2.28837 20.5486 2.33219 20.6398 2.39262 20.7196C2.45305 20.7995 2.52887 20.8665 2.61559 20.9165C2.70232 20.9666 2.7982 20.9989 2.89758 21.0113C2.99695 21.0237 3.09782 21.0161 3.19421 20.989C3.29061 20.9618 3.38059 20.9156 3.45884 20.8531C3.53709 20.7906 3.60203 20.713 3.64982 20.625C5.41607 17.5725 8.53794 15.75 12.0001 15.75C15.4623 15.75 18.5842 17.5725 20.3504 20.625C20.3982 20.713 20.4632 20.7906 20.5414 20.8531C20.6197 20.9156 20.7097 20.9618 20.806 20.989C20.9024 21.0161 21.0033 21.0237 21.1027 21.0113C21.2021 20.9989 21.2979 20.9666 21.3847 20.9165C21.4714 20.8665 21.5472 20.7995 21.6076 20.7196C21.6681 20.6398 21.7119 20.5486 21.7365 20.4515C21.7611 20.3544 21.7661 20.2534 21.751 20.1544C21.736 20.0554 21.7012 19.9604 21.6489 19.875ZM6.75013 9C6.75013 7.96165 7.05804 6.94662 7.63492 6.08326C8.21179 5.2199 9.03173 4.54699 9.99104 4.14963C10.9504 3.75227 12.006 3.64831 13.0244 3.85088C14.0428 4.05345 14.9782 4.55347 15.7124 5.28769C16.4467 6.02192 16.9467 6.95738 17.1493 7.97578C17.3518 8.99418 17.2479 10.0498 16.8505 11.0091C16.4531 11.9684 15.7802 12.7883 14.9169 13.3652C14.0535 13.9421 13.0385 14.25 12.0001 14.25C10.6082 14.2485 9.27371 13.6949 8.28947 12.7107C7.30522 11.7264 6.75162 10.3919 6.75013 9Z"
                    fill="#D92D20"
                />
            </svg>
        </div>
        <div class="title-wrapper">
            <div class="flex flex-row justify-between self-stretch">
                <span>Block Contact</span>
                <svg
                    @click="close"
                    class="cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M18 6L6 18M6 6L18 18"
                        stroke="#A4A7AE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <p>Blocking this contact will prevent all communication between both of you.</p>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
<style lang="scss" scoped>
.header-div {
    display: flex;
    padding: 24px 24px 0px 24px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding-bottom: 16px;
    .icon-div {
        @apply flex justify-center items-center flex-col gap-5 relative;
        padding: 12px;
        border-radius: 28px;
        border: 8px solid var(--Error-50, #fef3f2);
        background: var(--Error-100, #fee4e2);

        .pattern {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
        }
        .icon {
            position: absolute;
            width: 48px;
            height: 48px;
            padding: 2px;
            border-radius: 28px;
            border: 8px solid var(--Error-50, #fef3f2);
            background: var(--Error-100, #fee4e2);
        }
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        span {
            color: var(--Gray-900, #181d27);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        p {
            color: var(--Gray-600, #535862);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
</style>
