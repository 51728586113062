<template>
    <div class="mt-[24px] mx-[24px]">
        <PrimeTabs :value="selectedTab">
            <PrimeTabList class="w-full flex justify-between">
                <PrimeTab value="0" class="w-[242px]">Scan Activity</PrimeTab>
                <PrimeTab value="1" class="w-[230px]">Rapid Response Escalation</PrimeTab>
            </PrimeTabList>
            <PrimeTabPanels>
                <PrimeTabPanel value="0">
                    <ScanActivityTab />
                </PrimeTabPanel>
                <PrimeTabPanel value="1">
                    <RapidReponseEscalationTab />
                </PrimeTabPanel>
            </PrimeTabPanels>
        </PrimeTabs>
    </div>
</template>
<script setup>
import ScanActivityTab from './ScanActivityTab.vue';
import RapidReponseEscalationTab from './RapidReponseEscalationTab.vue';

const selectedTab = ref('0');
</script>
<style scoped>
.prime-tab-list {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}
</style>
