<template>
    <div class="flex flex-col justify-center items-end self-stretch pt-8">
        <div class="flex justify-end items-center gap-3 flex-[1_0_0] px-6 pb-6 pl-[88px] w-[350px]">
            <prime-button
                @click="close"
                class="btn-cancel w-[76px] flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
            >
                Cancel
            </prime-button>

            <prime-button
                class="btn-add w-[112px] flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
            >
                Verify & Add
            </prime-button>
        </div>
    </div>
</template>

<script setup>
import PrimeButton from 'primevue/button';

const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
<style lang="scss" scoped>
.btn-cancel {
    color: var(--Zinc-700, #3f3f46);
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: var(--Base-White, #fff);
}

.btn-add {
    color: #ffffff;
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: #2563f3;
}
</style>
