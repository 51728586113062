<template>
    <div class="w-[400px] h-auto rounded-lg bg-[#FFFFFF]">
        <header-create-group-chat @closeDialog="closeDialog" />
        <main-create-group-chat />
        <footer-create-group-chat @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderCreateGroupChat from './HeaderCreateGroupChat.vue';
import MainCreateGroupChat from './MainCreateGroupChat.vue';
import FooterCreateGroupChat from './FooterCreateGroupChat.vue';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>