<template>
    <div class="w-[532px] h-[606px] rounded-[8px] pt-[12px] pr-[36px] pb-[36px] pl-[36px] bg-[#FFFFFF] flex flex-col gap-[36px]">
        <header-gift-cert @closeDialog="closeDialog" />
        <main-gift-cert />
    </div>
</template>
<script setup>
import HeaderGiftCert from './HeaderGiftCert.vue';
import MainGiftCert from './MainGiftCert.vue';
const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>