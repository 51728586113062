<template>
    <div class="add-contact-container">
        <div class="section-wrapper">
            <div class="section-container">
                <label class="add-contact-label">Display Name</label>
                <div class="input-container">
                    <prime-input-text class="input-field" placeholder="John Doe" v-model="name" />
                </div>
            </div>
        </div>

        <div v-for="(email, index) in emailAdd" :key="index" class="section-wrapper">
            <div class="section-container">
                <label class="add-contact-label">Email Address</label>
                <div class="input-container">
                    <span class="px-3 text-[#717680]">
                        <EnvelopeIcon class="h-[20px] w-[20px] text-[#717680]" />
                    </span>
                    <prime-input-text v-model="emailAdd[index]" class="input-field" placeholder="Add an email address" />
                    <button :disabled="emailAdd.length === 1" @click="removeEmail(index)" class="delete-item-btn">
                        <TrashIcon class="h-[20px] w-[20px] text-[#717680]" />
                    </button>
                </div>
            </div>
        </div>

        <div v-if="emailAdd.length < 5" class="add-another-btn-container">
            <PlusIcon class="w-5 h-5 text-[#2563F3]" />
            <p class="add-another-btn" @click="addEmail">Add email</p>
        </div>

        <div v-for="(number, index) in phoneNumber" :key="index" class="section-wrapper">
            <div class="section-container">
                <label class="add-contact-label">Phone Number</label>
                <div class="input-container">
                    <span class="px-3 text-[#717680]">
                        <PhoneIcon class="h-[20px] w-[20px] text-[#717680]" />
                    </span>
                    <prime-input-text v-model="phoneNumber[index]" class="input-field" placeholder="Add phone number..." />
                    <button :disabled="phoneNumber.length === 1" @click="removephoneNumber(index)" class="delete-item-btn">
                        <TrashIcon class="h-[20px] w-[20px] text-[#717680]" />
                    </button>
                </div>
            </div>
        </div>

        <div v-if="phoneNumber.length < 5" class="add-another-btn-container">
            <PlusIcon class="w-5 h-5 text-[#2563F3]" />
            <p class="add-another-btn" @click="addphoneNumber">Add phone</p>
        </div>

        <div class="section-container">
            <label class="add-contact-label">Labels</label>

            <PrimeMultiSelect
                v-model="selectedLabels"
                :options="labels"
                optionLabel="name"
                filter
                placeholder="Select Labels"
                :maxSelectedLabels="3"
                class="w-full"
            />

            <!-- show labels -->
            <div class="flex gap-2 mt-2">
                <button class="custom-button" v-for="label in selectedLabels" :key="label.name" @click="removeLabel(label)">
                    {{ label.name }}

                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path
                            d="M9 3L3 9M3 3L9 9"
                            stroke="#A4A7AE"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <!-- actions -->
    <div class="add-contact-footer">
        <div class="footer-actions">
            <prime-button @click="close" variant="secondary" label="Cancel" />

            <prime-button
                label="Add Contact"
                variant="primary"
                :disabled="!canAddContact"
                @click="addContact"
                :loading="isAddingContact"
            />
        </div>
    </div>
</template>

<script setup>
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { PhoneIcon } from '@heroicons/vue/24/outline';
import PrimeInputText from 'primevue/inputtext';
import { useToast } from 'vue-toastification';
import { useContactsStore } from '~/stores/personal/contacts';

//handle close dialog
const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};

const emailAdd = ref([]);
const phoneNumber = ref([]);
const name = ref('');

const selectedLabels = ref([]);

const labels = ref([
    { name: 'Friends', code: 'friend' },
    { name: 'Gamers', code: 'gamer' },
    { name: 'Family', code: 'family' },
    { name: 'Coworkers', code: 'coworker' },
]);

const addEmail = () => {
    emailAdd.value.push('');
};

const removeEmail = (index) => {
    emailAdd.value.splice(index, 1);
};

const addphoneNumber = () => {
    phoneNumber.value.push('');
};

const removephoneNumber = (index) => {
    phoneNumber.value.splice(index, 1);
};

const removeLabel = (label) => {
    //find the label from selected Labels
    const index = selectedLabels.value.findIndex((l) => l.name === label.name);
    if (index !== -1) {
        selectedLabels.value.splice(index, 1);
    }
};

//computed, can add contact only if name is provided
const canAddContact = computed(() => {
    return name.value.length > 0;
});

const nuxtApp = useNuxtApp();
const personal_sotore = usePersonalStore();
const toast = useToast();
const isAddingContact = ref(false);
const store = useContactsStore();

//handle add contact
async function addContact() {
    isAddingContact.value = true;

    //prepare data
    const postData = {
        name: name.value,
        emails: emailAdd.value,
        phone_numbers: phoneNumber.value,
        tags: selectedLabels.value.map((label) => label.code),
        blocked: false,
    };

    //send data to server
    console.log(postData);

    const { data, error, status } = await usePbFetch(nuxtApp.$api.v3.people.contacts.create_contact(personal_sotore.person.id), {
        method: 'POST',
        body: [postData],
    });

    if (status.value === 'error') {
        isAddingContact.value = false;
        return toast.error('Something went wrong while adding contact');
    }

    //close dialog

    toast.success('Contact added successfully');
    store.fetchContacts();
    isAddingContact.value = false;
    close();
}
</script>

<style lang="scss" scoped>
.section-container {
    @apply flex flex-col items-start gap-1 w-full;
}

.input-container {
    @apply flex items-center w-full h-10 border border-[#D4D4D8] rounded-sm bg-[#FFF];
}

.delete-item-btn {
    @apply px-3 text-[#717680] hover:text-red-500 border-l border-[#D5D7DA] h-full flex items-center cursor-pointer;
}

.input-field {
    @apply flex-1 py-2 px-3 focus:outline-none border border-[#D4D4D8] border-x-[#FFF];
}

.add-another-btn-container {
    @apply flex justify-center items-center gap-1.5 cursor-pointer;

    .add-another-btn {
        @apply text-[#2563F3] font-inter text-sm font-semibold leading-5;
    }
}

.section-wrapper {
    @apply flex flex-col items-start gap-[6px] w-full;
}

.add-contact-container {
    @apply flex flex-col items-start gap-6 px-6 self-stretch;
    max-height: 70vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        display: block;
    }

    &::-webkit-scrollbar-track {
        background-color: hsl(240, 5%, 95%);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: hsl(240, 5%, 90%);
        border-radius: 10px;
    }
}

.add-contact-label {
    @apply text-[#3F3F46] font-inter text-sm font-medium leading-5;
}

.custom-button {
    color: var(--Gray-700, #414651);
    text-align: center;

    /* Text xs/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    display: flex;
    padding: 2px 3px 2px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 16px;
    border: 1px solid var(--Gray-200, #e9eaeb);
    background: var(--Gray-50, #fafafa);

    svg {
        display: flex;
        padding: 2px;
        flex-direction: column;
        align-items: flex-start;
    }
}

.add-contact-footer {
    @apply flex flex-col justify-center items-end self-stretch pt-8;

    .footer-actions {
        @apply flex justify-end items-center gap-3 flex-[1_0_0] px-6 pb-6 pl-[88px] w-[350px];
    }

    .btn-cancel {
        @apply w-[76px] flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)];
        color: var(--Zinc-700, #3f3f46);
        border: 1px solid var(--Zinc-300, #d4d4d8);
        background: var(--Base-White, #fff);
    }

    .btn-add {
        @apply w-[144px] flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)];
        color: #ffffff;
        border: 1px solid var(--Zinc-300, #d4d4d8);
        background: #2563f3;
    }
}
</style>
