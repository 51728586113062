<template>
    <div class="upd-ftr-main">
        <div class="upd-ftr-con">
            <PrimeButton @click="close" label="Cancel" variant="secondary" />
            <PrimeButton label="Update tags" />
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
<style lang="scss" scoped>
.upd-ftr-main {
    display: flex;
    padding-top: 32px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    .upd-ftr-con {
        display: flex;
        padding: 0px 24px 24px 24px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
}
</style>
