import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { CompanyExposure } from '~/types/personal/details';

export const useExposureSidebarStore = defineStore('exposure-sidebar', () => {
    const selectedExposure = ref<CompanyExposure | null>(null);

    // escalation
    const hasEscalationBegan = ref(false);
    const isBeginEscalationLoading = ref(false);
    const escalationTimer = ref('00:00');
    const escalationStartTime = ref<Date | null>(null);
    //to destroy the interval
    const escalationTimerInterval = ref<NodeJS.Timeout | null>(null);

    function beginEscalation() {
        isBeginEscalationLoading.value = true;

        setTimeout(() => {
            hasEscalationBegan.value = true;
            escalationStartTime.value = new Date();
            isBeginEscalationLoading.value = false;
            updateEscalationTimer();
        }, 3000);
    }

    function updateEscalationTimer() {
        //every 1 second update escamation timer by 1 second, return 'mm:ss'
        escalationTimerInterval.value = setInterval(() => {
            if (!escalationStartTime.value) return;

            const now = new Date();
            const timeDiff = now.getTime() - escalationStartTime.value.getTime();
            let minutes: number | string = Math.floor((timeDiff / (1000 * 60)) % 60);
            let seconds: number | string = Math.floor((timeDiff / 1000) % 60);

            //if minutes is less than 10, add a 0 in front of it
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            //if seconds is less than 10, add a 0 in front of it
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            escalationTimer.value = `${minutes}:${seconds}`;
        }, 1000);
    }

    function abortEscalation() {
        hasEscalationBegan.value = false;
        escalationTimer.value = '00:00';
        escalationStartTime.value = null;
        if (escalationTimerInterval.value) {
            clearInterval(escalationTimerInterval.value);
            escalationTimerInterval.value = null;
        }
    }

    return {
        selectedExposure,
        hasEscalationBegan,
        isBeginEscalationLoading,
        beginEscalation,
        abortEscalation,
        escalationTimer,
    };
});
