<template>
    <div class="flex p-6 pt-6 pb-0 px-6 items-start gap-4 self-stretch pb-[20px]">
        <div
            class="flex w-12 h-12 p-3 justify-center items-center rounded-[10px] border border-[#E9EAEB] bg-[#F5F5F5 shadow-[inset_0px_0px_0px_1px_rgba(10,13,18,0.18),inset_0px_-2px_0px_0px_rgba(10,13,18,0.05),0px_1px_2px_0px_rgba(16,24,40,0.05)]"
        >
            <user-plus />
        </div>
        <div class="flex flex-col items-start gap-1 flex-1">
            <div class="self-stretch flex flex-row justify-between">
                <p class="text-[#09090B] font-inter text-lg font-semibold leading-7">Add Contact</p>
                <div class="flex justify-center items-center rounded-lg text-[#A4A7AE] cursor-pointer" @click="close">
                    <XMarkIcon class="h-[24px] w-[24px]" />
                </div>
            </div>
            <p class="text-[#52525C] font-inter text-sm font-normal leading-5">
                Add a new contact and engage in secure communication.
            </p>
        </div>
    </div>
</template>
<script setup>
import UserPlus from '~/assets/images/icons/user-plus.svg';

import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
