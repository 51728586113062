<template>
    <div class="w-[460px] h-[751px]">
        <div class="flex h-[68px] pb-6 items-center gap-1 self-stretch">
            <p class="text-[#18181B] font-inter text-[20px] font-semibold leading-[32px]">Gift Certificate Code</p>
        </div>
        <div class="flex flex-col items-start gap-1.5 self-stretch">
            <div class="flex w-[460px]">
                <prime-input-text
                    placeholder="Enter code"
                    v-model="giftCode"
                    class="input-giftcode w-full px-3 py-2 bg-white outline-none rounded-l-md"
                />
                <prime-button
                    class="btn-apply px-4 py-2 font-inter text-[14px] font-semibold leading-[20px] bg-white rounded-r-md"
                    @click="showMessage = true"
                >
                    Apply
                </prime-button>
            </div>
            <div class="flex items-center gap-2 py-1 self-stretch">
                <!-- <div class="flex items-center gap-2 py-1 self-stretch" v-if="giftCode && showMessage"> -->
                <CheckCircleIcon class="w-[20px] h-[20px] check-icon text-[#4FC460]" />
                <p class="text-zinc-500 font-inter text-[14px] font-medium leading-[20px]">
                    Success! Your gift certificate code has been applied!
                </p>
            </div>
        </div>
        <div class="divider w-full border-t my-9"></div>
        <subscription-summary />
        <subscription-terms />
    </div>
</template>
<script setup>
import SubscriptionSummary from './SubscriptionSummary.vue';
import SubscriptionTerms from './SubscriptionTerms.vue';

import PrimeInputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';

import { CheckCircleIcon } from '@heroicons/vue/24/solid';

const showMessage = ref(false);
const giftCode = ref(null);
</script>
<style lang="scss" scoped>
.input-giftcode,
.btn-apply {
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: var(--Base-White, #fff);
}
.btn-apply {
    color: var(--Zinc-700, #3f3f46);
}
.divider {
    border: 1px solid var(--Zinc-300, #d4d4d8);
}
</style>
