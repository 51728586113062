<template>
    <div class="flex flex-col pt-6 items-center gap-2.5 self-stretch">
        <div class="flex w-[344px] pb-2 justify-center items-start gap-2">
            <PrimeCheckbox v-model="checked" binary />
            <p class="text-[#71717B] font-inter text-[14px] font-normal leading-[20px]">
                I agree to the
                <a href="#" class="text-[#2563F3] underline">Terms of Service</a> &
                <a href="#" class="text-[#2563F3] underline">Privacy Policy</a>
            </p>
        </div>
        <div class="h-[36px] w-full">
            <prime-button variant="primary" class="w-full">Purchase</prime-button>
        </div>
    </div>
</template>
<script setup>
import PrimeButton from 'primevue/button';
const checked = ref(false);
</script>
