<template>
    <div class="w-[544px] h-auto rounded-lg bg-[#FFFFFF]">
        <div class="flex items-start gap-4 self-stretch p-6 pt-6 pb-0 bg-[#FFFFFF] rounded-t-lg">
            <div class="flex w-12 h-12 p-3 justify-center items-center rounded-full bg-[#F5F5F5]">
                <vault-icon />
            </div>
            <div class="items-start gap-[4px] flex-1 flex flex-col">
                <header-phone-verification @closeDialog="closeDialog" />
                <main-phone-verification />
            </div>
        </div>
        <footer-phone-verification @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderPhoneVerification from './HeaderPhoneVerification.vue';
import MainPhoneVerification from './MainPhoneVerification.vue';
import FooterPhoneVerification from './FooterPhoneVerification.vue';

import VaultIcon from '~/assets/images/icons/vault-icon.svg';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
