<template>
    <div class="main-div">
        <HeaderRemove @closeDialog="closeDialog" />
        <MainRemove />
        <FooterRemove @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderRemove from './HeaderRemove.vue';
import MainRemove from './MainRemove.vue';
import FooterRemove from './FooterRemove.vue';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
<style lang="scss" scoped>
.main-div {
    display: flex;
    width: 544px;
    max-width: 544px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Base-White, #fff);
    box-shadow: 0px 20px 24px -4px rgba(10, 13, 18, 0.08), 0px 8px 8px -4px rgba(10, 13, 18, 0.03),
        0px 3px 3px -1.5px rgba(10, 13, 18, 0.04);
}
</style>
