<template>
    <div class="upd-lbl-main">
        <div class="upd-wrapper">
            <PrimeMultiSelect
                v-model="selectedTags"
                :options="contactStore.tags"
                optionLabel="name"
                filter
                placeholder="Select or add a tag"
                :maxSelectedLabels="3"
                class="w-full"
            />
            <div class="flex gap-[8px]">
                <button class="custom-button" v-for="tag in selectedTags" :key="tag.name">
                    {{ tag.name }}

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        @click="removeTag(tag)"
                    >
                        <path
                            d="M9 3L3 9M3 3L9 9"
                            stroke="#A4A7AE"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useContactsStore } from '~/stores/personal/contacts';

const contactStore = useContactsStore();
const selectedTags = ref([]);

const removeTag = (tag) => {
    const index = selectedTags.value.findIndex((l) => l.name === tag.name);
    if (index !== -1) {
        selectedTags.value.splice(index, 1);
    }
};
</script>
<style lang="scss" scoped>
.upd-lbl-main {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    .upd-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
}

.custom-button {
    color: var(--Gray-700, #414651);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    padding: 2px 3px 2px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 16px;
    border: 1px solid var(--Gray-200, #e9eaeb);
    background: var(--Gray-50, #fafafa);

    svg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
