<template>
    <div class="select-dev-branch">
        <div class="current-branch mb-4">
            <p class="text-xs text-gray-500">Current Branch</p>
            <p class="text-sm font-medium" v-if="isGettingBranch">Fetching...</p>
            <p class="text-sm font-medium text-green-700" v-else>{{ currentBranch }}</p>
        </div>

        <h3 class="text-sm font-semibold mb-4">Select Dev Branch</h3>
        <div class="branch-selector">
            <PrimeSelect v-model="selectedBranch" :options="branches" placeholder="Select a branch" class="w-full" />

            <PrimeButton
                :disabled="!selectedBranch || !currentBranch"
                variant="secondary"
                label="Select Branch"
                @click="onBranchChange"
                class="w-full"
                :loading="isSettingBranch"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import PrimeSelect from 'primevue/select';

const selectedBranch = ref(null);
const currentBranch = ref(null);
const isGettingBranch = ref(false);
const isSettingBranch = ref(false);

const branches = ref(['develop', 'responsive-identity-vault', 'wire-identity-vault']);

const getCurrentBranch = async () => {
    isGettingBranch.value = true;
    const response = await fetch('https://hive-branch.privacy-bee.workers.dev');

    currentBranch.value = await response.text();
    isGettingBranch.value = false;
};

onBeforeMount(async () => {
    getCurrentBranch();
});

const onBranchChange = async (event) => {
    isSettingBranch.value = true;
    await fetch(`https://hive-branch.privacy-bee.workers.dev/?set=${selectedBranch.value}`);

    await getCurrentBranch();
    isSettingBranch.value = false;

    window.location.reload();
};
</script>

<style scoped>
.select-dev-branch {
    @apply bg-white rounded-lg p-6 w-[400px];
    @apply p-6;
}

.branch-selector {
    @apply w-full max-w-md gap-4 flex flex-col;
}
</style>
