<template>
    <div class="main-inc">
        <div class="relative">
            <div class="circle-1"></div>
            <div class="circle-2"></div>
            <div class="circle-3"></div>
            <div class="circle-4"></div>
            <img src="https://avatar.iran.liara.run/public" alt="User Avatar" />
        </div>
        <div class="caller-wrapper">
            <span>Sarah C</span>
            <p>is calling you</p>
        </div>
    </div>
    <div class="call-action">
        <div class="action-wrapper">
            <PrimeButton label="Reject" class="w-full" variant="error">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path
                            d="M18.9594 13.8946C18.7942 14.1059 18.5654 14.2586 18.3068 14.3299C18.0483 14.4012 17.7736 14.3875 17.5234 14.2907L15.5891 12.8063C15.574 12.7953 15.5601 12.7827 15.5477 12.7688L9.02968 5.59537C8.99056 5.55261 8.9641 5.49981 8.95326 5.44287C8.94241 5.38594 8.94762 5.32711 8.96829 5.27297C8.98896 5.21882 9.02428 5.17149 9.0703 5.13626C9.11632 5.10104 9.17124 5.0793 9.2289 5.07349C12.5883 4.71255 16.0836 5.78599 18.593 8.29536C20.1547 9.85708 20.3055 12.1594 18.9594 13.8946ZM4.71328 2.70474C4.65839 2.64287 4.59177 2.59251 4.51728 2.55658C4.44278 2.52064 4.3619 2.49985 4.27931 2.49541C4.19672 2.49097 4.11408 2.50296 4.03616 2.53069C3.95824 2.55843 3.8866 2.60135 3.82539 2.65697C3.76419 2.7126 3.71463 2.77982 3.67959 2.85474C3.64455 2.92965 3.62473 3.01078 3.62128 3.09342C3.61783 3.17605 3.63081 3.25855 3.65948 3.33613C3.68814 3.41371 3.73192 3.48483 3.78828 3.54536L5.94609 5.91958C4.62392 6.4753 3.42205 7.28212 2.40703 8.29536C0.844526 9.85786 0.694526 12.1594 2.04062 13.8946C2.20579 14.1059 2.43457 14.2586 2.69315 14.3299C2.95172 14.4012 3.22639 14.3875 3.47656 14.2907L7.30468 12.9336L7.32734 12.9251C7.52045 12.8478 7.69125 12.7237 7.82426 12.5638C7.95727 12.4039 8.04829 12.2134 8.08906 12.0094L8.55 9.70396C8.76921 9.62828 8.99279 9.56593 9.21953 9.51724L16.2898 17.2954C16.3447 17.3572 16.4113 17.4076 16.4858 17.4435C16.5603 17.4795 16.6412 17.5003 16.7238 17.5047C16.8064 17.5091 16.889 17.4971 16.967 17.4694C17.0449 17.4417 17.1165 17.3988 17.1777 17.3431C17.2389 17.2875 17.2885 17.2203 17.3235 17.1454C17.3586 17.0705 17.3784 16.9893 17.3818 16.9067C17.3853 16.8241 17.3723 16.7416 17.3436 16.664C17.315 16.5864 17.2712 16.5153 17.2148 16.4547L4.71328 2.70474Z"
                            fill="white"
                        />
                    </svg>
                </template>
            </PrimeButton>
            <PrimeButton label="Accept" class="w-full" variant="success">
                <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path
                            d="M18.6156 13.6781C18.4763 14.7367 17.9564 15.7084 17.1531 16.4117C16.3497 17.1151 15.3177 17.5019 14.25 17.5C8.04688 17.5 3.00001 12.4531 3.00001 6.24996C2.99809 5.18222 3.38492 4.15029 4.08824 3.34691C4.79155 2.54353 5.76326 2.02364 6.82188 1.88434C7.08958 1.85165 7.36067 1.90642 7.59468 2.04046C7.8287 2.17451 8.01309 2.38064 8.12032 2.62809L9.77032 6.31168V6.32106C9.85242 6.51047 9.88633 6.71728 9.86901 6.92299C9.8517 7.12871 9.7837 7.32693 9.6711 7.49996C9.65704 7.52106 9.64219 7.54059 9.62657 7.56012L8.00001 9.48825C8.58516 10.6773 9.82891 11.9101 11.0336 12.4968L12.9352 10.8789C12.9538 10.8632 12.9734 10.8486 12.9938 10.8351C13.1666 10.7198 13.3655 10.6494 13.5725 10.6303C13.7794 10.6112 13.9878 10.644 14.1789 10.7257L14.1891 10.7304L17.8695 12.3797C18.1174 12.4865 18.3241 12.6707 18.4585 12.9048C18.593 13.1388 18.6481 13.4101 18.6156 13.6781Z"
                            fill="white"
                        />
                    </svg>
                </template>
            </PrimeButton>
        </div>
    </div>
    <div class="caller-details">
    <div
      v-for="(item, index) in callerDetails"
      :key="index"
      :class="item.isRisk ? 'details-row-risk' : 'details-row'"
    >
      <p>{{ item.label }}</p>

      <template v-if="item.isRisk">
        <div class="badge-div">
          <p>{{ item.value }}</p>
        </div>
      </template>

      <template v-else>
        <span>{{ item.value }}</span>
      </template>
    </div>
  </div>
</template>
<script setup>
const callerDetails = [
  { label: 'Full Name', value: 'Sarah Connor' },
  { label: 'Privacy Risk Score', value: '26%', isRisk: true },
  { label: 'Company', value: 'Privacy Bee' },
  { label: 'Phone', value: '555-867-5309' }
]
</script>
<style lang="scss" scoped>
.main-inc {
    display: flex;
    width: 120px;
    padding-top: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    img {
        height: 120px;
        align-self: stretch;
        border-radius: 120px;
        border: 2px solid #e74341;
        position: relative;
        z-index: 1;
    }

    span {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    p {
        color: var(--Zinc-400, #9f9fa9);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .caller-wrapper {
        @apply flex flex-col justify-center;
    }

    .circle-1,
    .circle-2,
    .circle-3,
    .circle-4 {
        position: absolute;
        border: 2px solid #e74341;
        border-radius: 50%;
    }

    .circle-1 {
        width: 140px;
        height: 140px;
        top: -10px;
        left: -10px;
        animation: circle-animation 4s infinite ease-in-out;
    }

    .circle-2 {
        width: 160px;
        height: 160px;
        top: -20px;
        left: -20px;
        animation: circle-animation 6s infinite ease-in-out;
        animation-delay: 1s;
    }

    .circle-3 {
        width: 180px;
        height: 180px;
        top: -30px;
        left: -30px;
        animation: circle-animation 8s infinite ease-in-out;
        animation-delay: 2s;
    }

    .circle-4 {
        width: 200px;
        height: 200px;
        top: -40px;
        left: -40px;
        animation: circle-animation 10s infinite ease-in-out;
        animation-delay: 3s;
    }

    @keyframes circle-animation {
        0% {
            border: solid 1px #fff;
            transform: scale(0);
        }
        50% {
            border: solid 1px #e74341;
            transform: scale(1);
        }
        100% {
            border: solid 1px #fff;
            transform: scale(0);
        }
    }
}
.call-action {
    width: 100%;
    display: flex;
    padding-top: 32px;
    flex-direction: row;
    align-items: center;
    .action-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0px 24px 24px 24px;
        align-items: center;
        gap: 24px;
        justify-items: center;
    }
}
.caller-details {
    width: 100%;

    .badge-div{
        
        display: flex;
padding: 2px 8px;
align-items: start;
border-radius: 16px;
border: 1.5px solid var(--Red-500, #E74341);
background: var(--Red-500, #E74341);
p {
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 18px;
}
    }
}

.details-row {
    display: flex;
    padding: 4px 24px;
    justify-content: start;
    align-items: start;
    gap: 24px;
    p {
        width: 100%;
        color: var(--Zinc-400, #9f9fa9);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    span {
        width: 100%;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}
.details-row-risk {
    display: flex;
    padding: 4px 24px;
    justify-content: start;
    align-items: start;
    gap: 24px;
    p {
        width: 47%;
        color: var(--Zinc-400, #9f9fa9);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}
</style>
