<template>
    <div class="w-[544px] h-[360px] rounded-[8px] bg-[#FFFFFF]">
        <div class="flex flex-row p-[24px] pt-[24px] pb-0 px-[24px] items-start gap-[16px] self-stretch">
            <div class="flex w-[48px] h-[48px] p-[12px] justify-center items-center rounded-full bg-[#F5F5F5]">
                <CreditCardIcon class="text-gray-500" />
            </div>
            <div class="flex flex-col items-start gap-1 flex-1">
                <div class="self-stretch flex flex-row justify-between">
                    <p class="text-[#09090B] font-inter text-lg font-semibold leading-7">Update Payment Information</p>
                    <div class="flex justify-center items-center rounded-lg text-[#A4A7AE]" @click="closeDialog">
                        <XMarkIcon class="h-[24px] w-[24px]" />
                    </div>
                </div>
                <p class="text-[#52525C] font-inter text-sm font-normal leading-5">Enter your card information below.</p>
                <main-update-payment-info />
            </div>
        </div>
        <footer-update-payment-info />
    </div>
</template>
<script setup>
import MainUpdatePaymentInfo from './MainUpdatePaymentInfo.vue';
import FooterUpdatePaymentInfo from './FooterUpdatePaymentInfo.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { CreditCardIcon } from '@heroicons/vue/24/outline';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
