<template>
    <div class="flex flex-col p-[12px_36px_36px] items-start gap-9 rounded-lg bg-[#FFFFFF] h-[734px] w-[532px]">
        <header-gift-cert-purchased @closeDialog="closeDialog" />
        <main-gift-cert-purchased />
    </div>
</template>
<script setup>
import HeaderGiftCertPurchased from './HeaderGiftCertPurchased.vue';
import MainGiftCertPurchased from './MainGiftCertPurchased.vue';
const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
