<template>
    <div class="flex flex-col items-start gap-[10px] pt-[12px] self-stretch">
        <div class="flex w-[344px] pb-[8px] items-start gap-[8px]">
            <PrimeCheckbox v-model="checked" binary />
            <p class="text-[#71717B] font-inter text-[14px] font-normal leading-[20px]">
                I agree to the
                <a href="https://privacybee.com/terms-of-service/" target="_blank" class="text-[#2563F3] underline">
                    Terms of Service
                </a>
                &
                <a href="https://privacybee.com/privacy-policy/" target="_blank" class="text-[#2563F3] underline">
                    Privacy Policy
                </a>
            </p>
        </div>
        <div class="h-[36px] w-full">
            <prime-button variant="primary" class="w-full">Purchase</prime-button>
        </div>
        <p class="h-[48px] self-stretch text-[#71717B] text-center font-inter text-[12px] font-normal leading-[24px]">
            Subscription will automatically renew until canceled.
        </p>
    </div>
</template>
<script setup>
import PrimeCheckbox from 'primevue/checkbox';
import PrimeButton from 'primevue/button';

const checked = ref(false);
</script>
