<template>
    <div class="w-[400px] h-auto rounded-lg bg-[#FFFFFF]">
        <header-add-contact @closeDialog="closeDialog" />
        <main-add-contact @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderAddContact from './HeaderAddContact.vue';
import MainAddContact from './MainAddContact.vue';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
