<template>
    <div class="main">
        <div class="text-wrapper">
            <div class="flex flex-row justify-between self-stretch">
                <span>Upload and attach files</span>
                <svg @click="close" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M18 6L6 18M6 6L18 18"
                        stroke="#A4A7AE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <p>Upload and attach files to your message.</p>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
<style lang="scss" scoped>
.main {
    display: flex;
    padding: 24px 24px 20px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }

    span {
        color: var(--Gray-900, #181d27);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }

    p {
        color: var(--Gray-600, #535862);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
</style>
