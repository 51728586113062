<template>
    <div class="credit-card">
        <billing-pattern class="pattern" />

        <div class="card-header">
            <div class="logo">
                <CardLogo />
            </div>
        </div>

        <div class="card-body">
            <div class="card-info">
                <div class="card-name">{{ name.toUpperCase() }}</div>
                <div class="card-number">{{ formattedCardNumber }}</div>
            </div>

            <div class="card-amount">
                <span>${{ creditAmount }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BillingPattern from '@/assets/images/illustrations/billing-pattern.svg';
import CardLogo from '@/assets/images/branding/card-logo.svg';

// Define props with TypeScript types
interface Props {
    creditAmount: number;
    name: string;
    cardNumber: string;
}

// Use defineProps with type annotation
const props = defineProps<Props>();

// Format card number with spaces for display (1234 1234 1234 1234)
const formattedCardNumber = computed(() => {
    return props.cardNumber
        .replace(/\s/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
});
</script>

<style scoped>
.pattern {
    position: absolute;
    left: 0;
    bottom: 0;
}

.credit-card {
    width: 320px;
    height: 180px;
    padding: 18px 25px;
    color: white;
    font-family: sans-serif;
    position: relative;
    overflow: hidden;

    border-radius: 16px;
    border: 1px solid #fff;
    background: var(--gradient-gray-90070045-deg, linear-gradient(45deg, #181d27 0%, #414651 100%));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.card-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.logo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.logo-icon {
    font-size: 18px;
    color: #ffd700;
}

.logo-text {
    font-weight: bold;
    font-size: 16px;
}

.card-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 1;
}

.card-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.card-name {
    color: var(--Base-White, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.card-number {
    color: var(--Base-White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.4px;
}

.card-amount {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(9, 9, 11, 0.6);
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: var(--Zinc-50, #fafafa);
    text-align: right;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.48);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.7px;
}
</style>
