<template>
    <div class="flex flex-col items-start self-stretch">
        <div class="flex pb-6 items-center gap-1 self-stretch">
            <p class="text-[#18181B] font-inter text-xl font-semibold leading-8">Summary</p>
        </div>
        <div class="flex justify-between items-center self-stretch py-3 border-t border-b border-dashed border-[#D4D4D8]">
            <p class="text-[#27272A] font-inter text-base font-normal leading-8">Recipient Name</p>
            <p class="text-[#09090B] text-right font-inter text-base font-medium leading-8">Harry Maugans</p>
        </div>
        <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
            <p class="text-[#27272A] font-inter text-base font-normal leading-8">Recipient Email</p>
            <p class="text-[#09090B] text-right font-inter text-base font-medium leading-8">harry@privacybee.com</p>
        </div>
        <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
            <p class="text-[#27272A] font-inter text-base font-normal leading-8">Certificate value</p>
            <p class="text-[#09090B] text-right font-inter text-base font-medium leading-8">USD $50.00</p>
        </div>
        <div class="flex flex-col items-center self-stretch pt-6 gap-6">
            <div class="flex flex-col justify-center items-center self-stretch px-6 pt-0">
                <!-- card -->
                <credit-card-mockup name="Harry" :creditAmount="50" cardNumber="1234 5678 1234 5678" />
            </div>
            <div class="flex h-[28px] py-[4px] justify-center items-center gap-[8px] self-stretch" v-if="isEmailSent">
                <CheckCircleIcon class="w-[20px] h-[20px] check-icon text-[#4FC460]" />
                <p class="text-zinc-500 font-inter text-[14px] font-medium leading-[20px]">Email sent to harry@privacybee.com!</p>
            </div>
            <div class="flex h-[28px] py-[4px] justify-center items-center gap-[8px] self-stretch" v-else></div>
            <footer-gift-cert-purchased @handleEmailClick="handleEmailClick" :isEmailSent="isEmailSent" />
        </div>
    </div>
</template>
<script setup lang="ts">
import CreditCardMockup from '~/components/modals/personal/CreditCardMockup.vue';

import FooterGiftCertPurchased from './FooterGiftCertPurchased.vue';

import { CheckCircleIcon } from '@heroicons/vue/24/solid';

const isEmailSent = ref(false);

const handleEmailClick = () => {
    isEmailSent.value = true;
};
</script>
