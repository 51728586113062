<template>
    <div class="exposure-container">
        <div class="exposure-card">
            <div class="exposure-header">
                <div class="exposure-company">
                    <img :src="store.selectedExposure.logo" class="exposure-logo" />
                    <div>
                        <p class="exposure-company-name">{{ store.selectedExposure.name }}</p>
                        <p class="exposure-company-type">{{ store.selectedExposure.type }}</p>
                    </div>
                </div>
                <div>
                    <div class="exposure-risk-badge">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4.00002" cy="4" r="3" fill="#F04438" />
                        </svg>
                        <p class="exposure-risk-text">90 - High Risk</p>
                    </div>
                </div>
            </div>
            <div class="exposure-dates">
                <div class="exposure-date-item">
                    <p class="exposure-date">Oct. 11, 2024</p>
                    <p class="exposure-date-label">Initial Scan</p>
                </div>
                <div class="exposure-date-item">
                    <p class="exposure-date">Oct. 11, 2024</p>
                    <p class="exposure-date-label">Latest Scan</p>
                </div>
                <div class="exposure-date-item">
                    <p class="exposure-date">Feb. 11, 2024</p>
                    <p class="exposure-date-label">Next Scan</p>
                </div>
            </div>
            <prime-button variant="secondary" @click="openCompanyProfile"> Company Profile </prime-button>
        </div>
    </div>
</template>

<script setup>
import FacebookLogo from '~/assets/images/png/main/personal/exposure-sidebar/facebook-logo.png';
import { useExposureSidebarStore } from '~/stores/components/modals/exposure-sidebar';
const dialogRef = inject('dialogRef');

const store = useExposureSidebarStore();

const openCompanyProfile = () => {
    usePbRouter('/personal/companies?company=' + store.selectedExposure.slug);
    // @ts-ignore
    dialogRef.value.close();
};
</script>

<style lang="scss" scoped>
.exposure-container {
    @apply container border-b border-[#E4E4E7] p-[24px] h-[273px];
    background-image: url('/assets/images/png/main/personal/exposure-sidebar/bg-image.png');
}

.exposure-card {
    @apply py-[24px] px-[25px] w-[485px] h-[225px] flex flex-col gap-[25px] rounded-[4px] border border-[#D5D7DA] bg-[#FFF] shadow-[0px_1px_3px_0px_rgba(10,13,18,0.10),0px_1px_2px_-1px_rgba(10,13,18,0.10)];
}

.exposure-header {
    @apply h-[50px] flex flex-row justify-between;
}

.exposure-company {
    @apply gap-[12px] flex flex-row;
}

.exposure-logo {
    @apply h-[48px] w-[48px];
    border-radius: 50%;
    background-color: #f0f0f0;
    border: 3px solid var(--Base-White, #fff);
    box-shadow: 0px 4px 6px -1px rgba(10, 13, 18, 0.1), 0px 2px 4px -2px rgba(10, 13, 18, 0.06);
}

.exposure-company-name {
    @apply text-[#09090B] font-inter text-[16px] font-semibold leading-normal;
}

.exposure-company-type {
    @apply text-[#52525B] font-inter text-[12px] font-normal leading-[18px];
}

.exposure-risk-badge {
    @apply inline-flex p-[2px_8px_2px_6px] items-center gap-[4px] rounded-[16px] border border-[#FECDCA] bg-[#FEF3F2];
}

.exposure-risk-text {
    @apply text-[#B42318] text-center font-inter text-[12px] font-medium leading-[18px];
}

.exposure-dates {
    @apply flex justify-between items-center;
}

.exposure-date-item {
    @apply flex w-[145px] flex-col items-center gap-[4px];
}

.exposure-date {
    @apply text-[#09090B] text-center font-inter text-[14px] font-medium leading-[18px];
}

.exposure-date-label {
    @apply text-[#52525B] text-center font-inter text-[12px] font-normal leading-[18px];
}

.exposure-profile-btn {
    @apply w-full flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)];
    color: var(--Zinc-700, #3f3f46);
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: var(--Base-White, #fff);
}
</style>
