<template>
    <div class="flex flex-col items-start gap-1.5 pt-4 self-stretch">
        <div class="divider"></div>
        <div class="flex items-start" v-if="!isSentViaSms">
            <p class="text-[#71717B] font-inter text-[14px] font-normal leading-[20px]">
                Send code
                <span
                    :class="{
                        'text-[#2563F3]': !isSmsSent,
                        'text-[rgba(83, 88, 98, 0.5)]': isSmsSent,
                        underline: true,
                        'cursor-pointer': true,
                    }"
                    @click="isSentViaSms = true"
                >
                    via SMS</span
                >.
            </p>
        </div>
        <div v-if="isSentViaSms" class="w-full">
            <div class="flex flex-col items-start gap-1.5 self-stretch pt-[12px]">
                <p class="text-[#414651] text-sm font-medium leading-5 font-inter">Verification code</p>
            </div>
            <div class="flex items-center gap-2 w-[364px] h-[64px]">
                <p class="text-red-500">for otp component</p>
            </div>
            <div class="flex items-start">
                <p class="text-[#71717B] font-inter text-[14px] font-normal leading-[20px]">
                    Didn't get a code?
                    <span
                        :class="{
                            'text-[#2563F3]': !isSmsSent,
                            'text-[rgba(83, 88, 98, 0.5)]': isSmsSent,
                            underline: true,
                            'cursor-pointer': true,
                        }"
                        @click="isSmsSent = true"
                    >
                        Click to resend</span
                    >.
                </p>
            </div>
            <div class="divider my-[12px]" v-if="isSmsSent || isInvalidCode"></div>
            <div class="flex items-center gap-2 y-1 self-stretch" v-if="isSmsSent">
                <CheckCircleIcon class="w-[20px] h-[20px] check-icon text-[#4FC460]" />
                <p class="text-[#535862] text-sm font-normal leading-5 font-inter">Verification code re-sent!</p>
            </div>
            <div class="flex items-center gap-2 y-1 self-stretch" v-if="isInvalidCode">
                <ExclamationCircleIcon class="w-[20px] h-[20px] check-icon text-[#D92D20]" />
                <p class="text-[#D92D20] text-sm font-normal leading-5 font-inter">Invalid verification code</p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';

const isSentViaSms = ref(false);
const isSmsSent = ref(false);
const isInvalidCode = ref(false);
</script>
<style lang="scss" scoped>
.divider {
    height: 1px;
    align-self: stretch;
    background: var(--Zinc-300, #d4d4d8);
}
</style>
