<template>
    <div class="block-main-div">
        <img
            class="avatar-div"
            src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/b21a3f9e-1b77-4862-9ed0-3a5cc1ee19ca/d5g19vp-724ee6d4-3752-4593-bc24-da624174980f.jpg/v1/fit/w_695,h_800,q_70,strp/heisenberg_by_boibastard_d5g19vp-375w-2x.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODAwIiwicGF0aCI6IlwvZlwvYjIxYTNmOWUtMWI3Ny00ODYyLTllZDAtM2E1Y2MxZWUxOWNhXC9kNWcxOXZwLTcyNGVlNmQ0LTM3NTItNDU5My1iYzI0LWRhNjI0MTc0OTgwZi5qcGciLCJ3aWR0aCI6Ijw9Njk1In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.6Zr6QrGrm5tTx7vAziIZhK7JOQMI2ZKRUjOKcfWUD-I"
            alt="User Avatar"
        />
        <span>{{ contactStore.selectedContact.name }}</span>
    </div>
</template>
<script setup>
import { useContactsStore } from '~/stores/personal/contacts';

const contactStore = useContactsStore();
</script>
<style lang="scss" scoped>
.block-main-div {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 80px;
    align-self: stretch;
    .avatar-div {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
    }
    span {
        color: var(--Gray-900, #181d27);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
</style>
