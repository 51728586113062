<template>
    <div class="flex flex-col items-start gap-4 px-6 self-stretch">
        <div class="flex flex-col items-start gap-[6px] w-full">
            <div class="flex flex-col items-start gap-1 w-full">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">Group Name</label>
                <div class="flex items-center w-full h-10 border border-[#D4D4D8] rounded-sm bg-[#FFF] shadow-xs">
                    <prime-input-text
                        class="flex-1 py-2 px-3 focus:outline-none border border-[#D4D4D8] border-x-[#FFF]"
                        placeholder="Group Name"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-col items-start gap-[6px] w-full">
            <div class="flex flex-col items-start gap-1 w-full cursor-pointer" @click="handleShowContact">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">Contacts</label>
                <div class="flex gap-[8px] min-h-10 items-center w-full border border-[#D4D4D8] rounded-sm bg-[#FFF] shadow-xs">
                    <span class="px-3 text-[#717680]">
                        <MagnifyingGlassIcon class="h-[20px] w-[20px] text-[#717680]" />
                    </span>
                    <p v-if="selectedContacts.length === 0">Search Contacts</p>
                    <div v-else class="flex flex-wrap gap-[8px] items-center w-full max-h-[256px] overflow-y-auto">
                        <div
                            v-for="(contact, index) in selectedContacts"
                            :key="index"
                            class="flex items-center justify-center gap-[3px] p-[2px_4px_2px_5px] rounded-md border border-[#D5D7DA] bg-[#FFF] h-[24px]"
                        >
                            <img :src="contact.picture" alt="User Avatar" class="w-4 h-4 rounded-full border border-[#D4D4D8]" />
                            <p class="text-[#414651] text-sm font-medium leading-5 text-center font-inter">
                                {{ contact.firstName }}
                            </p>
                            <XMarkIcon @click="removeLabel(index)" class="w-4 h-4 text-[#414651] cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="isShowContacts"
                class="flex flex-col w-full max-h-[256px] p-[4px_0] items-start flex-1 rounded-lg border border-[#E9EAEB] bg-white shadow-lg overflow-y-auto"
            >
                <div v-for="(contact, index) in allContacts" :key="index" class="flex px-1.5 py-0.5 items-center self-stretch">
                    <div
                        :class="{
                            'flex px-2.5 py-2 items-center gap-2 flex-1 rounded-lg cursor-pointer': true,
                            'bg-[#FAFAFA]': selectedContacts.some((c) => c.id === contact.id),
                            'bg-[#FFF]': !selectedContacts.some((c) => c.id === contact.id),
                        }"
                        @click="toggleSelection(contact)"
                    >
                        <img :src="contact.picture" alt="User Avatar" class="w-6 h-6 rounded-full border border-[#D4D4D8]" />
                        <p class="text-[#414651] text-sm font-medium leading-5 text-center font-inter">
                            {{ contact.firstName }} {{ contact.lastName }}
                        </p>

                        <CheckIcon
                            v-if="selectedContacts.includes(contact)"
                            class="w-5 h-5 text-[#2563F3] cursor-pointer ml-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { CheckIcon } from '@heroicons/vue/24/solid';

import PrimeInputText from 'primevue/inputtext';

const isShowContacts = ref(false);
const allContacts = ref([
    {
        id: 1,
        firstName: 'Phoenix',
        lastName: 'Baker',
        picture: 'https://avatar.iran.liara.run/public',
    },
    {
        id: 2,
        firstName: 'Olivia',
        lastName: 'Rhye',
        picture: 'https://avatar.iran.liara.run/public',
    },
    {
        id: 3,
        firstName: 'Lana',
        lastName: 'Steiner',
        picture: 'https://avatar.iran.liara.run/public',
    },
    {
        id: 4,
        firstName: 'Demi',
        lastName: 'Wilkinson',
        picture: 'https://avatar.iran.liara.run/public',
    },
    {
        id: 5,
        firstName: 'Candice',
        lastName: 'Wu',
        picture: 'https://avatar.iran.liara.run/public',
    },
    {
        id: 6,
        firstName: 'Natali',
        lastName: 'Craig',
        picture: 'https://avatar.iran.liara.run/public',
    },
]);

const selectedContacts = ref([]);

const toggleSelection = (contact) => {
    const index = selectedContacts.value.findIndex((c) => c.id === contact.id);
    if (index === -1) {
        selectedContacts.value.push(contact);
    } else {
        selectedContacts.value.splice(index, 1);
    }
};

const removeLabel = (index) => {
    selectedContacts.value.splice(index, 1);
};

const handleShowContact = () => {
    if (isShowContacts.value && selectedContacts.value.length !== 0) {
        return;
    } else {
        isShowContacts.value = true;
    }
};
</script>
