<template>
    <transition name="slide-in-right">
        <div class="sidebar-container">
            <div class="sidebar-header">
                <h2 class="sidebar-header-title">Exposure Details</h2>
                <button @click="closeDialog" class="p-2 rounded hover:bg-gray-200">
                    <XMarkIcon class="h-[24px] w-[24px]" />
                </button>
            </div>

            <div class="stepper-container" v-if="store.selectedExposure">
                <StatusDisplay :exposure="store.selectedExposure" />
            </div>

            <div class="notification-container" v-if="store.selectedExposure?.request_status === 'in_progress'">
                <div class="relative">
                    <div class="circle-1"></div>
                    <div class="circle-2"></div>
                    <InformationCircleIcon class="h-6 w-6 text-[#F6BB1E] fill-[white]" />
                </div>
                <p class="notification-text">
                    We're waiting on {{ store.selectedExposure?.name }} to respond to our deletion request.
                </p>
            </div>

            <SpecificExposure />
            <ActivityFeed />
        </div>
    </transition>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

import SpecificExposure from '~/components/side-bar/personal/exposure-sidebar/SpecificExposure.vue';
import ActivityFeed from '~/components/side-bar/personal/exposure-sidebar/ActivityFeed.vue';
import StatusDisplay from '~/components/main/personal/dashboard/StatusDisplay.vue';
import { useExposureSidebarStore } from '~/stores/components/modals/exposure-sidebar';
const dialogRef = inject('dialogRef');

const closeDialog = () => {
    // @ts-ignore
    dialogRef.value.close();
};

const store = useExposureSidebarStore();
</script>

<style lang="scss" scoped>
.sidebar-container {
    @apply bg-[#FFF] w-[532px] h-[100vh] flex flex-col overflow-y-auto right-0 fixed top-0;
    animation: slide-in-right 0.3s ease-in-out;

    .sidebar-header {
        @apply flex justify-between items-center w-full p-4;

        .sidebar-header-title {
            @apply text-[#18181B] font-inter text-[16px] ml-[15px] font-semibold leading-normal;
        }
    }

    .notification-container {
        @apply flex flex-row items-center p-[12px] px-[32px] gap-[16px] border-b border-[#E4E4E7];

        .notification-text {
            @apply text-[#414651] font-inter text-[14px] font-semibold leading-[20px];
        }
    }
}

.stepper-container {
    @apply flex justify-center items-center gap-3 py-2 px-6 bg-[#fafafa] border-t border-b border-[#e4e4e7] text-[#52525b] font-inter text-[12px] font-medium leading-normal;
}

.circle-1 {
    @apply absolute border h-8 w-8 border-[#F6BB1E] rounded-full;
    top: -18%;
    left: -18%;
    animation: circle-animation 4s infinite ease-in-out;
}

.circle-2 {
    @apply absolute border h-8 w-8 border-[#F6BB1E] rounded-full;
    top: -18%;
    left: -18%;
    animation: circle-animation 6s infinite ease-in-out;
    animation-delay: 1s;
}
@keyframes circle-animation {
    0% {
        border: solid 1px #fff;
        transform: scale(0);
    }
    50% {
        border: solid 1px #f6bb1e;
        transform: scale(1);
    }
    100% {
        border: solid 1px #fff;
        transform: scale(0);
    }
}

//using vue3 transition to slide in from right

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-right {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}
</style>
