<template>
    <div class="header-main w-full flex items-start gap-4 pt-6">
        <subscription-header class="w-12 h-12" />
        <div class="flex-1">
            <div class="header-title flex justify-between items-start">
                <p class="text-[#09090B] font-inter text-[18px] font-semibold leading-[28px]">Change Subscription</p>
                <XMarkIcon class="text-gray-400 w-5 h-5 cursor-pointer" @click="close"/>
            </div>
            <p class="text-gray-500 text-sm mt-1">
                All changes to your subscription will go into effect after the current billing period.
            </p>
        </div>
    </div>
</template>

<script setup>
import SubscriptionHeader from '~/assets/images/icons/subscription-header.svg';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['closeDialog']);

const close = () => {
  emit('closeDialog');
};
</script>

<style lang="scss" scoped>
.header-main {
    align-self: stretch;
}

.header-title {
    align-self: stretch;
    font-size: 18px;
    line-height: 28px;
}
</style>