<template>
    <div class="rapid-response-container">
        <div class="header-container">
            <LogoLock class="w-[32px] h-[48px]" />

            <div class="title-section">
                <p class="title">Signature Services</p>
                <p class="subtitle">Rapid Response</p>
            </div>

            <div class="timer-section">
                <div class="timer-display">
                    <p class="timer-text">{{ store.escalationTimer }}</p>
                </div>
                <p class="timer-label">Elapsed SOC Time</p>
            </div>
        </div>

        <div class="divider"></div>

        <BeginEscalation v-if="!store.hasEscalationBegan" />
        <EscalationProgress v-else />
    </div>
</template>

<script setup>
import LogoLock from '@/assets/images/branding/logo-lock.svg';
import BeginEscalation from './BeginEscalation.vue';
import EscalationProgress from './EscalationProgress.vue';
import { useExposureSidebarStore } from '~/stores/components/modals/exposure-sidebar';

const store = useExposureSidebarStore();
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.divider {
    @apply w-full h-[1px] bg-[#E4E4E7] mt-5 mb-6;
}

.rapid-response-container {
    display: flex;
    width: 485px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    position: relative;
    background: #fff;
    box-shadow: 0px 12px 24px -2px var(--Zinc-200, #e4e4e7);
    margin: 20px 0;
}

.rapid-response-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(150deg, #cf49f3, #e34a98, #5e66f8, #845dfd, #a057fd, #fff, #fff, #fff);
    background-size: 100% 100%;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    animation: gradient 6s ease-in-out infinite;
}

.header-container {
    @apply flex flex-row justify-between w-full gap-3;
}

.title-section {
    @apply flex flex-col items-start flex-grow mb-[2px];
}

.title {
    color: var(--Zinc-950, #09090b);

    /* Text lg/Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
}

.subtitle {
    overflow: hidden;
    color: var(--Zinc-700, #3f3f46);
    text-overflow: ellipsis;

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.timer-section {
    @apply flex flex-col items-end flex-grow mb-[2px];
}

.timer-display {
    @apply flex flex-row items-center;
}

.timer-text {
    color: var(--Zinc-700, #3f3f46);
    text-align: right;
    font-family: 'IBM Plex Mono';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
}

.timer-label {
    overflow: hidden;
    color: var(--Zinc-700, #3f3f46);
    text-align: right;
    text-overflow: ellipsis;

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.input-container {
    @apply flex flex-row justify-between w-full mt-[15px] mb-[20px];
}

.input-wrapper {
    @apply flex items-center w-[330px] h-10 border border-[#D4D4D8] rounded-sm bg-[#FFF] shadow-sm;
}

.input-wrapper-escalated {
    @apply w-[403px];
}

.input-label {
    @apply px-3 text-[#717680] border-r border-[#D5D7DA] h-full flex items-center;
}

.input-field {
    @apply flex-1 py-2 px-3 focus:outline-none border border-[#D4D4D8] border-x-[#FFF];
}

.action-button {
    @apply w-[144px] flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)];
}

.action-button-enabled {
    @apply text-[#fff] border-[1px] border-[#E9EAEB] bg-[#2563f3];
}

.action-button-disabled {
    @apply text-[#a4a7ae] border-[1px] border-[#d4d4d8] bg-[#fff];
}

.abort-button {
    @apply w-[71px];
}
</style>
