<template>
    <div class="flex flex-row justify-between w-full">
        <p class="text-[#09090B] font-inter text-lg font-semibold leading-7">Verify Email</p>
        <div class="flex justify-center items-center rounded-lg text-[#A4A7AE] cursor-pointer" @click="close">
            <XMarkIcon class="h-[24px] w-[24px]" />
        </div>
    </div>
    <div class="self-stretch">
        <p class="text-[#535862] text-sm font-normal leading-5 font-inter">
            Enter the verification code sent to the below email to add it to your Identity Vault. We'll start scanning for
            exposures linked to it.
            <span class="block mt-1">Email: joe@privacybee.com</span>
        </p>
    </div>
</template>
<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
