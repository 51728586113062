<template>
    <div class="sub-modal-container rounded-lg">
        <div class="main-container">
            <subscription-header @closeDialog="closeDialog" />
            <div class="divider"></div>
            <div class="flex items-start gap-9">
                <subscription-left-section />
                <subscription-right-section />
            </div>
        </div>
    </div>
</template>

<script setup>
import SubscriptionHeader from './SubscriptionHeader/SubscriptionHeader.vue';
import SubscriptionLeftSection from './SubscriptionLeftSection/SubscriptionLeftSection.vue';
import SubscriptionRightSection from './SubscriptionRightSection/SubscriptionRightSection.vue';

const dialogRef = inject('dialogRef');

const closeDialog = () => {
    dialogRef.value.close();
};
</script>

<style lang="scss" scoped>
.sub-modal-container {
    transform: scale(0.9);
    background: white;
    width: 1226px;
    height: 948px;
    font-family: Inter, sans-serif;
}
.main-container {
    display: flex;
    padding: 12px 36px 36px 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
}
.divider {
    height: 1px;
    align-self: stretch;
    background: var(--Zinc-300, #d4d4d8);
}
</style>
