<template>
    <div class="main-con">
        <HeaderUploadFile @closeDialog="closeDialog"  />
        <MainUploadFile />
        <FooterUploadFile />
    </div>
</template>
<script setup>
import HeaderUploadFile from './HeaderUploadFile.vue';
import MainUploadFile from './MainUploadFile.vue';
import FooterUploadFile from './FooterUploadFile.vue';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
<style lang="scss" scoped>
.main-con {
    display: flex;
    max-width: 480px;
    width: 480px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Base-White, #fff);
    box-shadow: 0px 20px 24px -4px rgba(10, 13, 18, 0.08), 0px 8px 8px -4px rgba(10, 13, 18, 0.03),
        0px 3px 3px -1.5px rgba(10, 13, 18, 0.04);
}
</style>
