<template>
    <div class="update-main-con">
        <HeaderUpdateLabel @closeDialog="closeDialog" />
        <MainUpdateLabel />
        <FooterUpdateLabel @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderUpdateLabel from './HeaderUpdateTag.vue';
import MainUpdateLabel from './MainUpdateTag.vue';
import FooterUpdateLabel from './FooterUpdateTag.vue';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
<style lang="scss" scoped>
.update-main-con {
    display: flex;
    width: 400px;
    max-width: 400px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Base-White, #fff);
    box-shadow: 0px 20px 24px -4px rgba(10, 13, 18, 0.08), 0px 8px 8px -4px rgba(10, 13, 18, 0.03),
        0px 3px 3px -1.5px rgba(10, 13, 18, 0.04);
}
</style>
