<template>
    <div class="flex pt-6 items-start gap-4 self-stretch">
        <gift-cert-icon class="h-[48px] w-[48px]" />
        <div class="flex flex-col items-start gap-1 flex-1">
            <div class="self-stretch flex flex-row justify-between">
                <p class="text-[#09090B] font-inter text-lg font-semibold leading-7">Purchase Gift Certificate</p>
                <div class="flex justify-center items-center rounded-lg text-[#A4A7AE]" @click="close">
                    <XMarkIcon class="h-[24px] w-[24px]" />
                </div>
            </div>
            <p class="text-[#52525C] font-inter text-sm font-normal leading-5">Placeholder text goes here</p>
        </div>
    </div>
</template>
<script setup>
import GiftCertIcon from '~/assets/images/icons/gift-cert-icon.svg';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['closeDialog']);

const close = () => {
    emit('closeDialog');
};
</script>
