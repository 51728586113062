import { defineStore } from 'pinia';
import { ref } from 'vue';

// Pricing plan interface
export interface PricingPlan {
    term: 'monthly' | '1year' | '2year' | '3year';
    essential: string;
    pro: string;
    signature: string;
}

type PlanType = 'Essentials' | 'Pro' | 'Signature';
export type Period = '1m' | '1y' | '2y' | '3y';

type PricingData = {
    original: number;
    yearlyDiscount: number;
    unitPrice?: number;
};

const pricingTable: Record<PlanType, Record<Period, PricingData>> = {
    Essentials: {
        '1m': { original: 19.0, yearlyDiscount: 0.0, unitPrice: 19.0 },
        '1y': { original: 228.0, yearlyDiscount: 132.03, unitPrice: 95.97 },
        '2y': { original: 456.0, yearlyDiscount: 287.05, unitPrice: 168.95 },
        '3y': { original: 684.0, yearlyDiscount: 469.5, unitPrice: 214.5 },
    },
    Pro: {
        '1m': { original: 39.0, yearlyDiscount: 0.0, unitPrice: 39.0 },
        '1y': { original: 468.0, yearlyDiscount: 271.0, unitPrice: 197.0 },
        '2y': { original: 936.0, yearlyDiscount: 589.21, unitPrice: 346.79 },
        '3y': { original: 1404.0, yearlyDiscount: 963.71, unitPrice: 440.29 },
    },
    Signature: {
        '1m': { original: 0, yearlyDiscount: 0, unitPrice: 0 }, // No monthly plan for Signature
        '1y': { original: 1899.0, yearlyDiscount: 1099.63, unitPrice: 799.37 },
        '2y': { original: 3798.0, yearlyDiscount: 2390.84, unitPrice: 1407.16 },
        '3y': { original: 5697.0, yearlyDiscount: 3910.42, unitPrice: 1786.58 },
    },
};

const multiLicenseDiscount: Record<number, number> = {
    2: 0.1, // 10% discount for 2 licenses
    3: 0.12, // 12% discount for 3 licenses
    4: 0.15, // 15% discount for 4+ licenses
};

export const useSubscriptionModalStore = defineStore('subscription-modal', () => {
    const selectedTerm = ref<Period>('1m');
    const selectedPlanType = ref<PlanType>('Essentials');
    const licenseCount = ref(1);

    function formatPrice(price: number): string {
        return `$${price.toFixed(2)} USD`;
    }

    function calculatePrice(
        plan_type: PlanType,
        period: Period,
        license_amount: number
    ): {
        finalPrice: number;
        annualDiscount: number;
        multiPersonDiscount: number;
        originalPrice: number;
        basePrice: number;
    } {
        // Return 0 for Signature monthly plan
        if (plan_type === 'Signature' && period === '1m') {
            return {
                finalPrice: 0,
                annualDiscount: 0,
                multiPersonDiscount: 0,
                originalPrice: 0,
                basePrice: 0,
            };
        }

        const plan = pricingTable[plan_type][period];
        const basePrice = plan.unitPrice || 0;
        const annualDiscount = plan.yearlyDiscount || 0;
        const originalPrice = plan.original || 0;

        let finalPrice = basePrice;
        let multiPersonDiscount = 0;

        if (license_amount > 1) {
            multiPersonDiscount = multiLicenseDiscount[Math.min(license_amount, 4)] || multiLicenseDiscount[4];
            finalPrice = basePrice * (1 - multiPersonDiscount);
        }

        finalPrice = Number((finalPrice * license_amount).toFixed(2));

        return {
            finalPrice,
            annualDiscount,
            multiPersonDiscount,
            originalPrice,
            basePrice,
        };
    }

    const benefits = {
        essentials: [
            '919+ Data Brokers & People Search Sites covered',
            'Mostly Automated removals, with human support',
            'Unlimited emails, phones, addresses, and alt. names',
            'Flexible Priority lower cost for non-urgent removals',
            'Continuous Monitoring with full monthly re-scans',
            'Real-Time Dashboard reporting on current efforts',
            'Monthly Email Report summarizing your progress',
        ],
        pro: [
            'Everything in Essentials',
            '178,274 Custom Sites covered for data removals',
            'Google, Yahoo, and Bing search monitoring',
            'Search Result Removal submitted on all deletions',
            'Social Media Listening critical doxxing protection',
            'Paste Site Coverage monitoring and removals',
            'Dark Web Monitoring for high-risk data breaches',
            'Mass-Marketing Opt-Out from major spam lists',
            'Blur My House on Google Maps and Apple Maps',
        ],
        signature: [
            'Everything in Essentials',
            'Priority Expedited Removals for faster takedowns',
            'US-Based Analysts exclusively handling removals',
            'Complex Takedowns requiring custom effort',
            'Legal Options reserved for maximum success rates',
            '24/7/365 SOC Escalation for critical exposures',
        ],
    };

    // Add a getter for the current price calculations
    const getCurrentPrices = () => {
        const price = calculatePrice(selectedPlanType.value, selectedTerm.value, licenseCount.value);

        return {
            basePrice: price.basePrice,
            finalPrice: price.finalPrice,
            annualDiscount: price.annualDiscount,
            multiPersonDiscount: price.multiPersonDiscount,
            originalPrice: price.originalPrice,
        };
    };

    return {
        selectedTerm,
        selectedPlanType,
        licenseCount,
        benefits,
        calculatePrice,
        formatPrice,
        getCurrentPrices,
    };
});
