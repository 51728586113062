import { useToast } from 'vue-toastification';
import type { ActivityFeedResponse, Activity } from '@/types/personal/contacts';

export const useContactsStore = defineStore('contacts', () => {
    const nuxtApp = useNuxtApp();
    const auth = useAuthStore();
    const personal_store = usePersonalStore();
    const toast = useToast();
    const selectedContact = ref(null);
    const chatMessages = ref<Activity[]>([]);

    //state
    const contactsList = ref([]);

    const tags = ref([
        { name: 'Friends', code: 'friend' },
        { name: 'Gamers', code: 'gamer' },
        { name: 'Family', code: 'family' },
        { name: 'Coworkers', code: 'coworker' },
    ]);

    async function fetchContacts() {
        if (!personal_store.person) return;

        const { data, error, status } = await usePbFetch(nuxtApp.$api.v3.people.contacts.get_contacts(personal_store.person.id));

        if (status.value == 'error') {
            toast.error('Something went wrong while fetching contacts');
        }

        contactsList.value = data.value.data;
    }

    async function fetchActivities() {
        const { data, error, status } = await usePbFetch<ActivityFeedResponse>(nuxtApp.$api.v3.apps.buzz.get_activity(10000));

        if (data.value && status.value === 'success') {
            //only show values that have type: chat_message on data.value.data
            const filteredChat = data.value.data.filter((item) => item.type === 'chat_message');

            //sort the chat messages by created_at
            filteredChat.sort((a, b) => {
                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            });

            chatMessages.value = filteredChat;
        }

        if (status.value === 'error') {
            console.log(error);
        }
    }

    async function sendMessage(message: object) {
        const { data, error, status } = await usePbFetch(nuxtApp.$api.v3.apps.buzz.log_activity(), {
            method: 'POST',
            body: message,
        });

        if (status.value === 'error') {
            toast.error('Something went wrong while sending message');
            console.log(error);
        }

        await fetchActivities();
    }

    return {
        contactsList,
        selectedContact,
        chatMessages,
        fetchContacts,
        fetchActivities,
        sendMessage,
        tags,
    };
});
