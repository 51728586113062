<template>
    <div class="w-[544px] h-auto rounded-lg bg-[#FFFFFF]">
        <div class="flex items-start gap-4 self-stretch p-6 pt-6 pb-0 bg-[#FFFFFF] rounded-t-lg">
        <div class="flex w-12 h-12 p-3 justify-center items-center rounded-full bg-[#F5F5F5]">
            <vault-icon />
        </div>
        <div class="items-start gap-[4px] flex-1 flex flex-col">
            <header-email-verification @closeDialog="closeDialog" />
            <main-email-verification />
        </div>
    </div>
    <footer-email-verification @closeDialog="closeDialog" />
    </div>
</template>
<script setup>
import HeaderEmailVerification from './HeaderEmailVerification.vue';
import MainEmailVerification from './MainEmailVerification.vue';
import FooterEmailVerification from './FooterEmailVerification.vue';

import VaultIcon from '~/assets/images/icons/vault-icon.svg';

const dialogRef = inject('dialogRef');
const closeDialog = () => {
    dialogRef.value.close();
};
</script>
