<template>
    <div class="main-con">
        <div class="actions-con">
            <PrimeButton label="Cancel" variant="secondary" />
            <PrimeButton label="Attach files" />
        </div>
    </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.main-con {
    display: flex;
    padding-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .actions-con {
        display: flex;
        padding: 0px 24px 24px 24px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
}
</style>
