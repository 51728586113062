<template>
    <div class="escalation-progress">
        <div class="escalation-progress-header">
            <PrimeButton
                @click="store.abortEscalation"
                class="abort-escalation-btn"
                variant="primary"
                label="Abort Escalation"
            ></PrimeButton>
            <div class="id-text">ID #84930</div>
        </div>

        <div class="timeline">
            <div v-for="(event, index) in events" :key="index" class="timeline-item">
                <div
                    class="timeline-indicator"
                    :class="{
                        completed: event.status === 'completed',
                        'in-progress': event.status === 'in-progress',
                        pending: event.status === 'pending',
                    }"
                ></div>
                <div class="timeline-content">
                    <h4 class="event-title">{{ event.title }}</h4>
                    <p class="event-date" v-if="event.date">{{ event.date }}</p>
                    <p class="event-details" v-if="event.details">{{ event.details }}</p>
                    <div class="event-avatar" v-if="event.avatar && event.status === 'in-progress'">
                        <img :src="event.avatar" alt="Analyst Avatar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useExposureSidebarStore } from '~/stores/components/modals/exposure-sidebar';

const store = useExposureSidebarStore();

const events = ref([
    {
        title: 'Rapid Response Activated',
        date: 'November 11, 2024 at 8:34pm',
        status: 'completed',
    },
    {
        title: 'Sent to SOC (Atlanta, GA)',
        date: 'December 11, 2024 at 8:34pm',
        status: 'completed',
    },
    {
        title: 'Assigned to Analyst',
        date: 'December 11, 2024 at 8:34pm',
        details: 'For support call 555-867-5309',
        status: 'in-progress',
        avatar: 'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
    },
    {
        title: 'Work in Progress',
        status: 'pending',
    },
    {
        title: 'Awaiting Confirmation',
        status: 'pending',
    },
    {
        title: 'Exposure Resolved',
        status: 'pending',
    },
]);
</script>

<style scoped>
.escalation-progress-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abort-escalation-btn {
    width: 100%;
    background: linear-gradient(180deg, var(--Zinc-900, #18181b) 0%, var(--Zinc-950, #09090b) 100%);
}

.id-text {
    margin-top: 12px;
    color: var(--Zinc-600, #52525c);
    text-align: center;
    font-family: 'IBM Plex Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    position: relative;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 35%;
        height: 1px;
        border-top: 1px dashed var(--Zinc-300, #d4d4d8);
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 35%;
        height: 1px;
        border-top: 1px dashed var(--Zinc-300, #d4d4d8);
    }
}

.escalation-progress {
    width: 100%;
}

.timeline {
    position: relative;
    padding-left: 2px;
    margin-top: 24px;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #e0e0e0;
}

.timeline-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 24px;
}

.timeline-indicator {
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #e0e0e0;
    border: 2px solid #fff;
}

.timeline-indicator.completed {
    background: #16a34a;
}

.timeline-indicator.in-progress {
    background: #006df5;
}

.timeline-indicator.pending {
    background: #e0e0e0;
}

.timeline-content {
    position: relative;
}

.event-title {
    color: #09090b;

    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
}

.event-date {
    color: #717680;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
}

.event-details {
    color: #717680;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.event-avatar {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.event-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
