<template>
    <div class="main-queue">
        <div class="main-con-up">
            <div class="container">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
                        <path
                            d="M0.75 4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883885L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4Z"
                            fill="white"
                            stroke="#D5D7DA"
                            stroke-width="1.5"
                        />
                        <path d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5" stroke="#D5D7DA" stroke-width="1.5" />
                    </svg>
                </div>
                <div class="details-con">
                    <div class="file-details">
                        <div class="file-name">
                            <span>Tech design requirements.pdf</span>
                            <div class="icon-holder">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path
                                        d="M10 3L4.5 8.5L2 6"
                                        stroke="white"
                                        stroke-width="1.6666"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <p>200 KB</p>
                    </div>
                    <div class="progress-con">
                        <div class="progress-bar-con">
                            <div class="bar"></div>
                        </div>
                        <p>100%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-con-up">
            <div class="container">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
                        <path
                            d="M0.75 4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883885L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4Z"
                            fill="white"
                            stroke="#D5D7DA"
                            stroke-width="1.5"
                        />
                        <path d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5" stroke="#D5D7DA" stroke-width="1.5" />
                    </svg>
                </div>
                <div class="details-con">
                    <div class="file-details">
                        <div class="file-name">
                            <span>Tech design requirements.pdf</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M10.6667 4V3.46667C10.6667 2.71993 10.6667 2.34656 10.5213 2.06134C10.3935 1.81046 10.1895 1.60649 9.93865 1.47866C9.65344 1.33333 9.28007 1.33333 8.53333 1.33333H7.46667C6.71993 1.33333 6.34656 1.33333 6.06135 1.47866C5.81046 1.60649 5.60649 1.81046 5.47866 2.06134C5.33333 2.34656 5.33333 2.71993 5.33333 3.46667V4M6.66667 7.66667V11M9.33333 7.66667V11M2 4H14M12.6667 4V11.4667C12.6667 12.5868 12.6667 13.1468 12.4487 13.5746C12.2569 13.951 11.951 14.2569 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.2569 3.74307 13.951 3.55132 13.5746C3.33333 13.1468 3.33333 12.5868 3.33333 11.4667V4"
                                    stroke="#A4A7AE"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <p>200 KB</p>
                    </div>
                    <div class="progress-con">
                        <div class="progress-bar-con">
                            <div class="bar"></div>
                        </div>
                        <p>100%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.main-queue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.main-con-up {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #e9eaeb);
    background: var(--Base-White, #fff);

    .container {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;

        .details-con {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;

            .file-details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                flex: 1 0 0;
                width: 100%;
                p {
                    overflow: hidden;
                    color: var(--Gray-600, #535862);
                    text-overflow: ellipsis;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .file-name {
                    @apply flex flex-row justify-between self-stretch;
                    span {
                        overflow: hidden;
                        color: var(--Gray-700, #414651);
                        text-overflow: ellipsis;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                    }

                    .icon-holder {
                        display: flex;
                        width: 16px;
                        height: 16px;
                        padding: 2px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        border: 1px solid #155eef;
                        background: #155eef;
                    }
                }
            }

            .progress-con {
                display: flex;
                align-items: center;
                gap: 12px;
                align-self: stretch;

                .progress-bar-con {
                    @apply w-full h-2 bg-[#E9EAEB] rounded-full;

                    .bar {
                        @apply h-full rounded-full w-full bg-[#155eef];
                    }
                }

                p {
                    color: var(--Gray-700, #414651);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>
