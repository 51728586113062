<template>
    <div class="signature-plan h-[108px] rounded-b-lg" @click="selectPlan" :class="{ disabled: isMonthlyPlan }">
        <div class="left-section flex-col w-[406px]">
            <div class="flex items-center gap-[12px]">
                <PrimeRadioButton
                    @click="selectPlan"
                    v-model="store.selectedPlanType"
                    name="Signature"
                    value="Signature"
                    :disabled="isMonthlyPlan"
                />
                <p class="text-[#18181B] font-inter text-[24px] font-semibold leading-[32px]">Signature</p>
                <span v-if="isMonthlyPlan" class="text-zinc-500 text-sm">(Not available monthly)</span>
            </div>
            <p class="text-zinc-600 text-[14px] font-normal mt-1">
                White-glove expedited priority for individuals in crisis or at high risk
            </p>
        </div>
        <div class="grid justify-items-end">
            <p class="text-[#18181B] font-inter text-[23px] font-semibold leading-8">
                {{
                    isMonthlyPlan
                        ? 'N/A'
                        : store.formatPrice(store.calculatePrice('Signature', store.selectedTerm, store.licenseCount).finalPrice)
                }}
            </p>
            <p class="text-zinc-600 text-[14px] font-normal leading-[24px]">User / Month</p>
        </div>
    </div>

    <div class="benefits" v-if="store.selectedPlanType === 'Signature'">
        <div class="benefits-list">
            <div v-for="(benefit, index) in store.benefits.signature" :key="index" class="benefit">
                <CheckCircleIcon class="w-[24px] h-[24px] check-icon" />
                <p class="text-zinc-600 text-[14px] font-normal leading-[24px] w-[236px]">{{ benefit }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import PrimeRadioButton from 'primevue/radiobutton';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useSubscriptionModalStore } from '@/stores/components/modals/subscription-modal';
import { computed } from 'vue';

const store = useSubscriptionModalStore();

const isMonthlyPlan = computed(() => store.selectedTerm === '1m');

const selectPlan = () => {
    if (!isMonthlyPlan.value) {
        store.selectedPlanType = 'Signature';
    }
};
</script>

<style lang="scss" scoped>
.signature-plan {
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-self: stretch;
    align-content: flex-start;
    border: 1px solid var(--Zinc-300, #d4d4d8);

    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: var(--Blue-10, #f0f6fe);
        transform: translateY(-2px);
    }
}
.custom-button {
    background: var(--Blue-50, #f0f6fe);
    color: #3b82f6;
    border: 1px solid var(--Blue-200, #c3dbfc);
    border-radius: 16px;
    font-weight: 600;
    padding: 2px 10px;
    font-size: 14px;
    width: 71px;
    height: 24px;
}

.benefits {
    display: flex;
    padding: 32px 32px 36px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border: 1px solid var(--Zinc-300, #d4d4d8);
}
.benefits-list {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 32px;
    align-self: stretch;
    flex-wrap: wrap;
}
.benefit {
    display: flex;
    min-width: 240px;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}
.check-icon {
    color: #4fc460;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
        background: inherit;
        transform: none;
    }
}
</style>
