<template>
    <div class="flex pt-[24px] flex-col items-start gap-[20px] self-stretch">
        <div class="flex items-start gap-[16px] self-stretch">
            <div class="flex flex-col items-start gap-[6px] flex-1">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">Name on card</label>
                <prime-input-text
                    class="flex p-2.5 px-3 items-center gap-2 self-stretch rounded-sm border border-[#D4D4D8] bg-[#FFF] shadow-xs"
                    placeholder="John Doe"
                    v-model="card.name"
                />
            </div>
            <div class="flex flex-col w-[112px] items-start gap-[6px]">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">Expiry</label>
                <prime-input-text
                    class="flex p-2.5 px-3 items-center gap-2 self-stretch rounded-sm border border-[#D4D4D8] bg-[#FFF] shadow-xs"
                    placeholder="John Doe"
                    v-model="card.expiry"
                />
            </div>
        </div>
        <div class="flex items-start gap-[16px] self-stretch">
            <div class="flex flex-col items-start gap-[6px] flex-1">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">Card number</label>
                <div
                    class="flex items-center gap-2 self-stretch rounded-[4px] border border-[#D5D7DA] bg-white shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
                >
                    <div
                        class="flex w-[34px] h-[24px] justify-center items-center rounded-[4px] border border-[#F5F5F5] bg-white"
                    >
                        <mastercard />
                    </div>
                    <prime-input-text
                        class="flex items-center gap-2 self-stretch rounded-sm border border-[#FFFFFF] bg-[#FFF] w-full"
                        placeholder="John Doe"
                        v-model="card.number"
                    />
                </div>
            </div>
            <div class="flex flex-col w-[112px] items-start gap-[6px]">
                <label class="text-[#3F3F46] font-inter text-sm font-medium leading-5">CVV</label>
                <prime-input-text
                    class="flex p-2.5 px-3 items-center gap-2 self-stretch rounded-sm border border-[#D4D4D8] bg-[#FFF] shadow-xs"
                    placeholder="John Doe"
                    v-model="card.cvv"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import PrimeInputText from 'primevue/inputtext';
import Mastercard from '~/assets/images/icons/Mastercard.svg';

const card = ref({
    name: 'Joe Berthelot',
    number: '1234 1234 1234 1234',
    expiry: '06/25',
    cvv: '•••',
});
</script>
