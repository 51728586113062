<template>
    <div class="escalation-container">
        <div class="title-container">
            <h1 class="title">Immediate Protection</h1>
            <p class="description">
                Our 24/7 Rapid Response Team, based in Privacy Bee's secure US SOC, handles critical risks and crisis situations
                with expert care.
            </p>
        </div>

        <div class="metrics-card">
            <div class="metric">
                <TimeIcon />
                <div class="metric-details">
                    <h2>15-30 minutes</h2>
                    <p>Avg. Resolution Time</p>
                </div>
            </div>
            <div class="metric">
                <CostIcon />
                <div class="metric-details">
                    <h2>$35 USD</h2>
                    <p>Cost Per 15 Min.</p>
                </div>
            </div>
        </div>

        <div class="checkboxes" v-if="personalStore.person?.pro">
            <div class="checkbox-item">
                <PrimeCheckbox v-model="agreementChecked" binary />
                <p>I agree to the time-based charged for expedited remediation of this exposure.</p>
            </div>
            <div class="checkbox-item">
                <PrimeCheckbox v-model="paymentChecked" binary />
                <p>I understand that my payment method on file ending in 8746 will be charged at the end of escalation period.</p>
            </div>
            <div class="checkbox-item">
                <PrimeCheckbox v-model="abortChecked" binary />
                <p>I understand that I can abort the escalation service at any time.</p>
            </div>
        </div>

        <PrimeButton
            v-if="personalStore.person?.pro"
            :loading="store.isBeginEscalationLoading"
            @click="handleEscalation"
            class="escalation-button"
            variant="primary"
            :disabled="isDisabled"
            :label="store.isBeginEscalationLoading ? 'Escalating...' : 'Begin Escalation'"
        ></PrimeButton>

        <div class="id-text" v-if="personalStore.person?.pro">ID #84930</div>

        <PrimeButton
            v-if="!personalStore.person?.pro"
            @click="useDynamicDialog('personal:subscription-modal')"
            class="escalation-button"
            variant="primary"
            label="Upgrade Subscription"
        ></PrimeButton>
    </div>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button';

import { ref } from 'vue';
import PrimeCheckbox from 'primevue/checkbox';
import TimeIcon from '@/assets/images/icons/escalations/time.svg';
import CostIcon from '@/assets/images/icons/escalations/cost.svg';
import { useExposureSidebarStore } from '~/stores/components/modals/exposure-sidebar';
import { usePersonalStore } from '~/stores/personal';
import { useDynamicDialog } from '~/composables/dynamic-dialog';
const agreementChecked = ref(false);
const paymentChecked = ref(false);
const abortChecked = ref(false);

const isDisabled = computed(() => !agreementChecked.value || !paymentChecked.value || !abortChecked.value);

const store = useExposureSidebarStore();
const personalStore = usePersonalStore();

const handleEscalation = () => {
    store.beginEscalation();
};
</script>

<style lang="scss" scoped>
.escalation-container {
    .title-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .title {
        color: var(--Zinc-950, #09090b);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
    }

    .description {
        color: var(--Zinc-700, #3f3f46);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }

    .metrics-card {
        border-radius: 8px;
        border: 1px solid var(--Zinc-300, #d4d4d8);
        background: repeating-linear-gradient(
            45deg,
            rgba(244, 244, 245, 0.5),
            rgba(244, 244, 245, 0.5) 2px,
            rgba(255, 255, 255, 0.5) 2px,
            rgba(255, 255, 255, 0.5) 20px
        );
        display: flex;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        margin: 12px 0 24px 0;

        .metric {
            display: flex;
            align-items: center;
            gap: 12px;

            .icon {
                width: 40px;
                height: 40px;
            }

            .metric-details {
                h2 {
                    color: var(--Zinc-950, #09090b);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */
                }

                p {
                    color: var(--Zinc-700, #3f3f46);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
    }

    .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 2rem;

        .checkbox-item {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            p {
                color: var(--Zinc-800, #27272a);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }
    }

    .escalation-button {
        width: 100%;
        background: linear-gradient(180deg, var(--Zinc-900, #18181b) 0%, var(--Zinc-950, #09090b) 100%);
    }

    .id-text {
        margin-top: 20px;
        color: var(--Zinc-600, #52525c);
        text-align: center;
        font-family: 'IBM Plex Mono';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        position: relative;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 35%;
            height: 1px;
            border-top: 1px dashed var(--Zinc-300, #d4d4d8);
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 35%;
            height: 1px;
            border-top: 1px dashed var(--Zinc-300, #d4d4d8);
        }
    }
}
</style>
