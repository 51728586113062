<template>
    <div class="flex flex-col justify-center items-end self-stretch pt-8">
        <div class="flex justify-end items-center gap-3 flex-[1_0_0] px-6 pb-6 pl-22">
            <prime-button
                class="btn-apply flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] flex-1 border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
                >Cancel</prime-button
            >
            <prime-button
                class="flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] flex-1 border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
                >Update</prime-button
            >
        </div>
    </div>
</template>
<style lang="scss" scoped>
.btn-apply {
    color: var(--Zinc-700, #3f3f46);
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: var(--Base-White, #fff);
}
</style>
