<template>
    <div class="pro-plan h-[108px]" @click="selectPlan">
        <div class="left-section flex-col w-[407px]">
            <div class="flex items-center gap-[12px]">
                <PrimeRadioButton @click="selectPlan" v-model="store.selectedPlanType" name="Pro" value="Pro" />
                <p class="text-[#18181B] font-inter text-[24px] font-semibold leading-[32px]">Pro</p>
            </div>
            <p class="text-zinc-600 text-[14px] font-normal mt-1">Comprehensive privacy across all sites and sources</p>
        </div>
        <div class="grid justify-items-end">
            <p class="text-[#18181B] font-inter text-[23px] font-semibold leading-8">
                {{ store.formatPrice(store.calculatePrice('Pro', store.selectedTerm, store.licenseCount).finalPrice) }}
            </p>
            <p class="text-zinc-600 text-[14px] font-normal leading-[24px]">User / Month</p>
        </div>
    </div>
    <div class="benefits" v-if="store.selectedPlanType === 'Pro'">
        <div class="benefits-list">
            <div v-for="(benefit, index) in store.benefits.pro" :key="index" class="benefit">
                <CheckCircleIcon class="w-[24px] h-[24px] check-icon" />
                <p class="text-zinc-600 text-[14px] font-normal leading-[24px] w-[236px]">{{ benefit }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import PrimeRadioButton from 'primevue/radiobutton';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useSubscriptionModalStore } from '@/stores/components/modals/subscription-modal';

const store = useSubscriptionModalStore();

const selectPlan = () => {
    store.selectedPlanType = 'Pro';
};
</script>

<style lang="scss" scoped>
.pro-plan {
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-content: flex-start;
    border: 1px solid var(--Zinc-300, #d4d4d8);

    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: var(--Blue-10, #f0f6fe);
        transform: translateY(-2px);
    }
}
.benefits {
    display: flex;
    padding: 32px 32px 36px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border: 1px solid var(--Zinc-300, #d4d4d8);
}
.benefits-list {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 32px;
    align-self: stretch;
    flex-wrap: wrap;
}
.benefit {
    display: flex;
    min-width: 240px;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}
.check-icon {
    color: #4fc460;
}
</style>
