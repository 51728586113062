<template>
    <div v-for="step in 3" :key="step" class="step-container" :class="{ 'w-full': step != 3 }">
        <!-- show check icon if step is done -->
        <div v-if="step < currentStep" class="checked-icon">
            <svg class="relative top" xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                <path
                    d="M11.4053 2.26138L5.38652 8.28013C5.24588 8.42067 5.05519 8.49962 4.85636 8.49962C4.65753 8.49962 4.46684 8.42067 4.32621 8.28013L0.969018 4.90513C0.828615 4.76451 0.749756 4.57392 0.749756 4.37521C0.749756 4.17649 0.828615 3.9859 0.969018 3.84528L1.90652 2.90778C2.04708 2.76781 2.23737 2.68922 2.43574 2.68922C2.63411 2.68922 2.82439 2.76781 2.96496 2.90778L4.87511 4.76028L9.41074 0.28044C9.55132 0.140323 9.74171 0.0616455 9.94019 0.0616455C10.1387 0.0616455 10.3291 0.140323 10.4696 0.28044L11.4048 1.19638C11.4751 1.26607 11.531 1.34899 11.5691 1.44037C11.6072 1.53175 11.6268 1.62977 11.6269 1.72878C11.6269 1.82779 11.6074 1.92583 11.5693 2.01724C11.5313 2.10865 11.4755 2.19162 11.4053 2.26138Z"
                    fill="white"
                />
            </svg>
        </div>

        <!-- show number if status not reached -->
        <div v-else-if="step === currentStep" class="status-number">
            {{ step }}
        </div>

        <!-- show label if status not reached -->
        <div v-else class="status-not-reached">
            {{ step }}
        </div>

        <p class="step-label" v-if="step === 1" :class="[statusLabelColor(1), { done: step < currentStep }]">Detected</p>
        <p class="step-label" v-if="step === 2" :class="[statusLabelColor(2), { done: step < currentStep }]">Request Sent</p>
        <p class="step-label" v-if="step === 3" :class="[statusLabelColor(3), { done: step < currentStep }]">Removed</p>

        <div class="step-connector" :class="{ '!bg-green-700': step < currentStep }" v-if="currentStep <= 2"></div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    exposure: any;
}>();

//computed status index
const currentStep = computed(() => {
    if (props.exposure.request_status === 'pending') return 1;
    if (props.exposure.request_status === 'in_progress') return 2;
    if (props.exposure.request_status === 'closed') return 3;

    return 1;
});

//function that returns the status color
const statusLabelColor = (step: number) => {
    // if step is current step, return blue
    if (step == currentStep.value) return '!text-[#2563EB]';
    // if step is done, return green
    if (step < currentStep.value) return '!text-[#16A34A]';
    // if step is not reached, return gray
    return 'text-[#52525B]';
};

const statusText = computed(() => {
    if (currentStep.value === 1) return 'Detected';
    if (currentStep.value === 2) return 'Request Sent';
    if (currentStep.value === 3) return 'Removed';
});
</script>

<style scoped lang="scss">
.step-container {
    @apply flex gap-3 md:gap-3 items-center select-none;

    .step-label {
        //dont break the line text
        white-space: nowrap;

        @apply text-xs md:text-sm font-medium;

        &.done {
            @apply hidden md:block;
        }
    }
}

.step-connector {
    @apply h-[2px] flex-1 md:w-full bg-[#D9D9D9] w-full;
}

.checked-icon {
    background: var(--Green-600, #3ea24b);
    @apply h-6 w-6 rounded-full flex items-center justify-center;
}

.status-number {
    @apply bg-[#2563EB] rounded-full w-6 h-6 flex items-center justify-center;
    @apply text-[#fff] text-center font-bold text-sm;
}

.status-not-reached {
    @apply bg-[#fff] rounded-full w-6 h-6 flex items-center justify-center;
    @apply text-[#52525B] text-center font-bold text-sm;
    @apply border border-[#D9D9D9];
}
</style>
