<template>
                <div class="flex h-[36px] justify-end items-center gap-[12px] self-stretch">
                <prime-button
                    @click="handleClick"
                    :disabled="isEmailSent"
                    :class="{
                        'bg-[#F5F5F5] text-[#A4A7AE]': props.isEmailSent,
                        'bg-white text-[#09090B]': !props.isEmailSent,
                    }"
                    class="flex p-[8px_12px] justify-center items-center gap-[4px] flex-1 border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
                >
                    Email Gift Certificate
                </prime-button>
                <prime-button
                    class="btn-apply flex p-[8px_12px] text-[#414651] justify-center items-center gap-[4px] flex-1 border-[1px] border-[#E9EAEB] rounded-[4px] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]"
                    >Copy Code to Clipboard</prime-button
                >
            </div>
</template>
<script setup>
import PrimeButton from 'primevue/button';

const props = defineProps({
  isEmailSent: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['handleEmailClick']);

const handleClick = () => {
  emit('handleEmailClick'); 
};
</script>
<style lang="scss" scoped>
.btn-apply {
    color: var(--Zinc-700, #3f3f46);
    border: 1px solid var(--Zinc-300, #d4d4d8);
    background: var(--Base-White, #fff);
}
</style>